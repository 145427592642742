import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import TextInput from "../common/TextInput";
import { ButtonComponent } from "../common/Button";
import {
  useAppDispatch,
  fundWalletAction,
  getUsersWalletAction,
} from "@buzztechng/vermo-redux";
import { NotificationAlert } from "../../utils/NotificationAlert";
import DropDown from "../common/DropDown";
interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
  walletValue: string;
  walletType?: string;
}
interface Values {
  values: string;
  kind: string;
}
const FundWalletModal = ({
  onCancel,
  visible,
  walletValue,
  walletType,
}: ModalInterface) => {
  const dispatcher = useAppDispatch();

  const [loadingState, setLoadingState] = useState(false);
  const fundWallet = (values: Values) => {
    console.log("val", values);
    const payload = {
      payinMethod: values.kind.toLowerCase(),
      amount: +values.values,
    };
    setLoadingState(true);
    dispatcher(fundWalletAction(payload))
      .unwrap()
      .then((originalPromiseResponse) => {
        setLoadingState(false);
        if (!originalPromiseResponse?.fundWallet?.status) {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.fundWallet?.msg
          );
        } else {
          NotificationAlert(
            "success",
            "Successful",
            originalPromiseResponse?.fundWallet?.msg
          );
          onCancel();
          window.location.href =
            originalPromiseResponse?.fundWallet?.paymentUrl;
          dispatcher(getUsersWalletAction({}));
        }
      })
      .catch((err) => {
        setLoadingState(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };
  console.log("wallet type", walletType);

  return (
    <>
      <ModalComponent visible={visible} onCancel={onCancel}>
        <div style={{ padding: "1vw", width: "100%" }}>
          <NotificationText>Fund wallet your Wallet</NotificationText>
          <br />
          <br />
          <Formik
            initialValues={{
              values: "",
              kind: "",
            }}
            onSubmit={(values: Values) => {
              fundWallet(values);
            }}
            validationSchema={yup.object().shape({
              values: yup.string().required("Please enter amount"),
              kind: yup.string().required("Please select a wallet Type"),
            })}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              errors,
              handleSubmit,
            }) => (
              <>
                <DropDown
                  onChange={setFieldValue}
                  name="kind"
                  label="Funding Method"
                  field={"kind"}
                  placeholder="Select your preferred funding method"
                  values={["Crypto", "Fiat"]}
                  error={errors.kind}
                />
                <TextInput
                  required
                  inputType="value"
                  name="values"
                  placeholder={"Enter the value you would like to fund"}
                  label={`Value (USDT and USD are acceptable only)`}
                  value={values.values}
                  onChange={handleChange}
                  error={errors.values}
                />
                <ButtonComponent
                  loading={loadingState}
                  onClick={handleSubmit}
                  text={"Fund Wallet"}
                  style={{ marginTop: "10px" }}
                />
              </>
            )}
          </Formik>
        </div>
        <br />
      </ModalComponent>
    </>
  );
};

export default FundWalletModal;
