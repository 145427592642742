import React from "react";
import styled from "styled-components";
import { Drawer as AntDrawer } from "antd";
import { device } from "../../theme/breakPoints";
import { Avatar } from "antd";
import { GeneralDataSelector, useApolloClient } from "@buzztechng/vermo-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dpcontainer,
  FollowerTag,
  Icon,
  LogoContainer,
  LogoutContainer,
  Nav,
} from "./SideMenu";
import vermoLogo from "../../asset/vermoLogo.png";
import { UserOutlined } from "@ant-design/icons";
import { FiUserCheck, FiUserPlus } from "react-icons/fi";
import { IoLogOut } from "react-icons/io5";
import { MdSpaceDashboard, MdSportsKabaddi } from "react-icons/md";
import { IoLogoGameControllerB } from "react-icons/io";
import { FaUserCircle, FaUsers, FaWallet } from "react-icons/fa";

interface IDrawer {
  showDrawer: boolean;
  setDrawerVisible: (data: boolean) => void;
}

const Drawer = ({ setDrawerVisible, showDrawer }: IDrawer) => {
  const generalState = GeneralDataSelector() as any;
  const userDetails = generalState?.userDetails?.me;
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const handleLogOut = () => {
    localStorage.clear();
    client.clearStore();
    navigate("/login", { replace: true });
  };
  const routes = [
    {
      id: 1,
      title: "Home",
      icon: <MdSpaceDashboard />,
      route: "/dashboard",
    },
    {
      id: 2,
      title: "Challenges",
      icon: <MdSportsKabaddi />,
      route: "/challenges",
    },
    {
      id: 3,
      title: "Sports",
      icon: <IoLogoGameControllerB />,
      route: "/sports",
    },
    {
      id: 5,
      title: "Wallet",
      icon: <FaWallet />,
      route: "/wallet",
    },
    {
      id: 6,
      title: "Users",
      icon: <FaUsers />,
      route: "/users",
    },
    {
      id: 4,
      title: "Profile",
      icon: <FaUserCircle />,
      route: "/profile",
    },
  ];
  return (
    <StyledDrawer
      bodyStyle={{ padding: "0px" }}
      closable={false}
      onClose={() => setDrawerVisible(false)}
      visible={showDrawer}
      placement="left"
      key="left"
      width={"85%"}
    >
      <DrawerContainer>
        <LogoContainer>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={vermoLogo} alt="logo" />
            <h1>Vermo</h1>
          </div>
        </LogoContainer>
        <br />
        <Dpcontainer>
          <Avatar
            size={{ xs: 64, sm: 64, md: 64, lg: 64, xl: 80, xxl: 100 }}
            src={userDetails?.img}
            style={{ flexShrink: 0, marginRight: "15px" }}
            icon={<UserOutlined />}
          />
          <p>{userDetails?.name}</p>
          <h5 className="points">0 points </h5>
          {/* <br /> */}
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FollowerTag>
              <span>
                <FiUserCheck />
              </span>
              <div>
                <p>0</p>
                <p>Followers</p>
              </div>
            </FollowerTag>
            <FollowerTag>
              <span>
                <FiUserPlus />
              </span>
              <div>
                <p>0</p>
                <p>Following</p>
              </div>
            </FollowerTag>
          </div>
          <br />
          <div>
            {routes.map((val, id) => (
              <Nav
                key={id}
                onClick={() => {
                  navigate(val.route);
                  setDrawerVisible(false);
                }}
                active={location.pathname === val.route ? true : false}
              >
                <Icon active={location.pathname === val.route ? true : false}>
                  {val.icon}
                </Icon>
                <p>{val.title}</p>
              </Nav>
            ))}
            <LogoutContainer onClick={() => handleLogOut()}>
              <div>
                <IoLogOut />
              </div>
              <h1>Logout</h1>
            </LogoutContainer>
          </div>
          {/* <svg
          style={{ backgroundColor: "red" }}
          width={size}
          height="100"
          viewBox="0 0 300 600"
        >
          <path
            strokeDashoffset="10"
            fill="none"
            width="100%"
            height="100%"
            strokeDasharray="35 5"
            stroke-width="5"
            stroke="blue"
            d="M 94 227 A 100 100 180 0 1 294 227"
          /> */}
          {/* <circle
            id="c"
            cx="60"
            cy="60"
            r="50"
            fill="none"
            stroke="blue"
            stroke-width={strokeWidth}
            stroke-dashoffset={strokeDashoffset}
            stroke-dasharray={strokeDasharray}
            transform="rotate(-90 60 60)"
          /> */}
          {/* </svg> */}
        </Dpcontainer>
      </DrawerContainer>
    </StyledDrawer>
  );
};

export default Drawer;
export const StyledDrawer = styled(AntDrawer)`
  border: none;
  /* background: ${(props) => props.theme.background}; */
  @media ${device.laptop} {
    display: none;
  }
`;
const DrawerContainer = styled.div`
  background: ${(props) => props.theme.foreground};
  height: 100vh;
  width: 100%;
  padding: 10px 0;
`;
