import React, { useEffect } from "react";
import { ButtonComponent } from "../common/Button";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import {
  ChallengesSelector,
  getSingleChallengeDetailsAction,
  useAppDispatch,
  acceptChallengeAction,
  getAllChallengeAction,
  UserWalletSelector,
  getMyLockedChallengeAction,
} from "@buzztechng/vermo-redux";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { NotificationAlert } from "../../utils/NotificationAlert";
import Avatar from "antd/lib/avatar/avatar";
import { ConfirmBetContent } from "../../Views/GameDetails/Style";
import { formatNumberAmount } from "../../utils/helperfuntions";
import { Container, Dropdown, Option } from "../common/DropDown";
interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
  data?: any;
}
const AcceptChallengeModal: React.FC<ModalInterface> = ({
  onCancel,
  visible,
  data,
}) => {
  const wallets = UserWalletSelector() as any;

  const { getSingleChallengeLoading, getSingleChallengeDetails } =
    ChallengesSelector() as any;
  console.log("data", data);
  const dispatcher = useAppDispatch();
  const [loadingState, setLoading] = React.useState(false);
  const [walletValue, setWalletValue] = React.useState() as any;

  useEffect(() => {
    dispatcher(getSingleChallengeDetailsAction({ gameBetId: +data?.id }));
  }, []);
  const handleSubmit = () => {
    const payload = {
      gameBetId: +data?.id,
      walletId: walletValue,
    };
    setLoading(true);
    dispatcher(acceptChallengeAction({ ...payload }))
      .unwrap()
      .then((originalPromiseResponse) => {
        setLoading(false);
        if (!originalPromiseResponse?.acceptChallenge?.status) {
          onCancel();

          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.acceptChallenge?.msg
          );
        } else {
          dispatcher(getAllChallengeAction({}));
          dispatcher(getMyLockedChallengeAction({}));
          onCancel();

          NotificationAlert(
            "success",
            "Challenge successfully locked.",
            originalPromiseResponse?.acceptChallenge?.msg
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };

  return (
    <>
      <ModalComponent visible={visible} onCancel={onCancel}>
        <div style={{ padding: "1vw", width: "100%", textAlign: "center" }}>
          {getSingleChallengeLoading ? (
            <div
              style={{
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <LoadingOutlined style={{ color: "#603be8" }} />
                <NotificationText>
                  Loading challenge details, please wait...
                </NotificationText>
              </div>
            </div>
          ) : (
            <div>
              <NotificationText>Challenge Details</NotificationText>
              <br />
              <ConfirmBetContent>
                <p className="details">
                  You are about to lock a bet challenge, a sum of
                  <span style={{ color: "green" }}>
                    {" "}
                    #
                    {formatNumberAmount(
                      +getSingleChallengeDetails?.singleChallengeDetails?.amount
                    )}
                  </span>{" "}
                  will be deducted from your wallet.
                </p>
                <br />

                <Avatar
                  size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                  src={
                    getSingleChallengeDetails?.singleChallengeDetails
                      ?.createdUser?.image
                  }
                  style={{
                    flexShrink: 0,
                    textAlign: "center",
                    margin: "0 auto",
                  }}
                  icon={<UserOutlined />}
                />
                <br />
                <br />
                <p className="detailsInfo">
                  Challenger's Name:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallengeDetails
                        ?.createdUser?.name
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  Game Type:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallengeDetails
                        ?.gameType
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  Bet Type:{" "}
                  <span>
                    {getSingleChallengeDetails?.singleChallengeDetails?.betType}
                  </span>
                </p>
                <p className="detailsInfo">
                  Challenger's Selection:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallengeDetails
                        ?.createdByTeam
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  My Accepted Selection:{" "}
                  <span>
                    {
                      getSingleChallengeDetails?.singleChallengeDetails
                        ?.acceptedByTeam
                    }
                  </span>
                </p>
                <p className="detailsInfo">
                  Stake Amount:{" "}
                  <span>
                    #
                    {formatNumberAmount(
                      +getSingleChallengeDetails?.singleChallengeDetails?.amount
                    )}
                  </span>
                </p>
                <p className="detailsInfo">
                  Winning:{" "}
                  <span>
                    #
                    {formatNumberAmount(
                      +getSingleChallengeDetails?.singleChallengeDetails
                        ?.amount * 2
                    )}
                  </span>
                </p>
                <p className="detailsInfo">Select Withdrawal Wallet: </p>
                <Container>
                  <Dropdown onChange={(e) => setWalletValue(e?.target?.value)}>
                    <Option disabled selected value="">
                      Select a wallet
                    </Option>
                    {wallets?.getUserWallets?.map((wallet: any) => (
                      <Option value={wallet?.walletId}>
                        {wallet?.currencyType === 1
                          ? "Crypto Wallet"
                          : "Fiat Wallet"}
                      </Option>
                    ))}
                  </Dropdown>
                </Container>
              </ConfirmBetContent>
              <br />
              <ButtonComponent
                disabled={!walletValue}
                loading={loadingState}
                onClick={handleSubmit}
                text={"Accept Challenge"}
                style={{ margin: "10px 0" }}
              />
              <br />
            </div>
          )}
        </div>
      </ModalComponent>
    </>
  );
};

export default AcceptChallengeModal;
