import React from "react";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { HiMenuAlt1 } from "react-icons/hi";
import {
  useAppDispatch,
  useAppSelector,
  toggleDark,
  toggleLight,
  mode,
  GeneralDataSelector,
} from "@buzztechng/vermo-redux";
import { AiOutlineMenu } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
interface MobileHeaderProps {
  showMenu?: boolean;
  setShowMenu: (val: boolean) => void | any;
}
const MobileHeader = (props: MobileHeaderProps) => {
  const lightmode = useAppSelector(mode);
  const dispatcher = useAppDispatch();
  const onChange = (): void => {
    lightmode === "dark" ? dispatcher(toggleLight()) : dispatcher(toggleDark());
  };
  const navigate = useNavigate();

  return (
    <MobileHeaderContainer>
      <div onClick={() => props.setShowMenu(true)}>
        <StyledIcon />
      </div>
      <h1
        onClick={() => {
          navigate("/dashboard");
        }}
        className="text"
      >
        VERMO
      </h1>
      <div onClick={onChange} className="others">
        {lightmode === "light" ? "🌞" : "🌜"}
      </div>
    </MobileHeaderContainer>
  );
};

export default MobileHeader;

const MobileHeaderContainer = styled.div`
  /* background-color: red; */
  box-shadow: ${(props) => props.theme.subtleShadow};
  background: ${(props) => props.theme.background};
  height: 49px;
  position: fixed;
  z-index: 15;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  padding: 0 10px;
  border-bottom: ${(props) => `1px solid ${props.theme.mutedColorPrimary}`};

  .text {
    font-weight: 500;
    margin: 0;
    letter-spacing: 2px;
    font-family: montserrat;
    font-size: 20px;
    color: ${(props) => props.theme.mutedColorTertiary};
  }
  .others {
    /* padding: 10px; */
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    background-color: ${(props) => props.theme.foreground};
    box-shadow: ${(props) => props.theme.authShadow};
    font-size: 17px;
  }

  @media ${device.laptop} {
    display: none;
  }
`;
const StyledIcon = styled(AiOutlineMenu)`
  color: ${(props) => props.theme.mutedColorTertiary};
  font-size: 20px;
  margin: 0;
`;
