import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { ButtonComponent } from "../../components/common/Button";
import { Router, useLocation, useNavigate } from "react-router-dom";
import { ModalComponent } from "../../components/common/ModalComponent";
//@ts-ignore
import { Helmet } from "react-helmet";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
  AwayOddSelection,
  ChatWeb,
  GameDetailsStyle,
  HomeOddSelection,
  MidContainer,
  PageTitle,
  TopSectionWrapper,
} from "../../components/common/StyleComponents";
import {
  formatNumberAmount,
  mapChallengeKey,
} from "../../utils/helperfuntions";
import Chat from "./components/Chat";
import {
  AddBet,
  AddBetContainer,
  ConfirmBet,
  ConfirmBetContent,
  Events,
} from "./Style";
import {
  FootballSelector,
  LiveGamesSelector,
  UserWalletSelector,
  createChallengeAction,
  useAppDispatch,
  footballDetailsAction,
  FootballDetailsSelector,
  LeagueDetailsSelector,
  mode,
  useAppSelector,
} from "@buzztechng/vermo-redux";
import {
  StyledIcon,
  StyledTimeline,
  StyledTimelineItem,
} from "../../components/common/TimeLine";
import { IoIosFootball } from "react-icons/io";
import TextInput from "../../components/common/TextInput";
import { Container, Dropdown, Option } from "../../components/common/DropDown";
import { NotificationAlert } from "../../utils/NotificationAlert";

const handicapValue = [-5, -4, -3, -2, -1, 1, 2, 3, 4, 5];
const GameDetails = () => {
  const dispatcher = useAppDispatch();
  const wallets = UserWalletSelector() as any;
  const gameDetails = FootballDetailsSelector() as any;

  const navigate = useNavigate();
  const location = useLocation();
  const matchData = location.state;
  const liveGamesList = LiveGamesSelector() as any;
  const matchData2 = React.useMemo(() => {
    return liveGamesList.filter((data: any) => {
      const myData =
        data?.apiFootballFixtureId ===
        matchData?.match?.others?.apiFootballFixtureId;
      return myData;
    });
  }, [liveGamesList]);
  const lightmode = useAppSelector(mode);
  const count = 30000;
  const [counter, setCounter] = useState(count);
  const [createBetModal, setCreateBetModal] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prev) => prev + 1);
    }, count);
    return () => (clearInterval(timer), console.log("cleared"));
  }, []);

  useEffect(() => {
    const DOMContentLoaded_event = document.createEvent("Event");
    DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
    window.document.dispatchEvent(DOMContentLoaded_event);
  }, [counter, lightmode]);

  const [val, setVal] = useState(false);

  useEffect(() => {
    if (matchData?.lockedChallenges) {
      dispatcher(
        footballDetailsAction({
          apiFootballFixtureId: matchData?.record?.gameFixtureId,
        })
      )
        .unwrap()
        .then((originalPromiseResponse) => {
          if (
            !originalPromiseResponse?.getFixtureByApiFootballFixtureId?.status
          ) {
            NotificationAlert(
              "error",
              "Error occured",
              originalPromiseResponse?.getFixtureByApiFootballFixtureId?.msg
            );
          }
        })
        .catch((err) => {
          setLoadingState(false);
          NotificationAlert("error", "Error occured", err.message);
        });
    }
  }, []);
  // console.log("matchData", lockedGamesList);
  const myMatchData = matchData2[0];
  const Footballtemplate = FootballSelector() as any;
  const [selectedOdd, setSelectedOdd] = React.useState("") as any;
  const [valueInput, setValueInput] = React.useState(0) as any;
  const [handicap, setHandicap] = React.useState(0) as any;
  const [walletValue, setWalletValue] = React.useState() as any;
  const [loading, setLoadingState] = React.useState(false);
  const leagueDetails = LeagueDetailsSelector() as any;
  const [selectedClub, setSelectedClub] = React.useState("");
  const [openModal, setModal] = React.useState(false);
  const handleSelected = (club: any, odd: any) => {
    if (selectedOdd && selectedClub) {
      setSelectedClub("");
      setSelectedOdd("");
    } else {
      setModal(true);
      setSelectedClub(club);
      setSelectedOdd(odd);
    }
  };
  const handleCreateModal = () => {
    setCreateBetModal(!createBetModal);
  };

  const handleCancelModal = () => {
    setSelectedClub("");
    setSelectedOdd("");
    setModal(false);
  };
  // console.log("", gameDetails?.getFixtureByApiFootballFixtureId?.events);
  // console.log(
  //   "dt",
  //   gameDetails?.getFixtureByApiFootballFixtureId?.apiFootballLeagueId
  // );
  const currentLeague = leagueDetails?.getLeagues?.filter((data: any) => {
    return (
      data?.apiFootballLeagueId ===
      gameDetails?.getFixtureByApiFootballFixtureId?.apiFootballLeagueId
    );
  });
  // console.log("current", currentLeague);
  const lockedChallengeEvent = React.useMemo(() => {
    return gameDetails?.getFixtureByApiFootballFixtureId?.events?.map(
      (event: any) => {
        return (
          <StyledTimeline mode="left">
            <StyledTimelineItem
              pending
              dot={
                <StyledIcon>
                  {event.type === "Goal" ? (
                    <IoIosFootball />
                  ) : (
                    <ClockCircleOutlined />
                  )}
                </StyledIcon>
              }
              label={`${event?.time}"`}
            >
              <p className="text">{event?.type}</p>
              <p className="text">{event?.detail}</p>
              <p className="text">
                {event?.team} F.C - {event?.player}
              </p>
            </StyledTimelineItem>
          </StyledTimeline>
        );
      }
    );
  }, [liveGamesList]);

  // const event = myMatchData?.events?.map((event: any) => {
  //   return (
  //     <StyledTimeline mode="left">
  //       <StyledTimelineItem
  //         pending
  //         dot={
  //           <StyledIcon>
  //             {event.type === "Goal" ? (
  //               <IoIosFootball />
  //             ) : (
  //               <ClockCircleOutlined />
  //             )}
  //           </StyledIcon>
  //         }
  //         label={`${event?.time}"`}
  //       >
  //         <p className="text">{event?.type}</p>
  //         <p className="text">{event?.detail}</p>
  //         <p className="text">
  //           {event?.team} F.C - {event?.player}
  //         </p>
  //       </StyledTimelineItem>
  //     </StyledTimeline>
  //   );
  // });

  const odds = Footballtemplate?.betType?.map((odds: any, index: any) => (
    <div key={index} style={{ marginBottom: "20px" }}>
      <p className="oddsTitle">{mapChallengeKey(odds.name)}</p>
      <div style={{ display: "flex", alignItems: "center" }}>
        <HomeOddSelection
          selected={
            selectedOdd === odds.name &&
            selectedClub === matchData?.match?.homeTeam
              ? true
              : false
          }
          onClick={() => handleSelected(matchData?.match?.homeTeam, odds.name)}
        >
          {matchData?.match?.homeTeam}
        </HomeOddSelection>
        <AwayOddSelection
          selected={
            selectedOdd === odds.name &&
            selectedClub === matchData?.match?.awayTeam
              ? true
              : false
          }
          onClick={() => handleSelected(matchData?.match?.awayTeam, odds.name)}
        >
          {matchData?.match?.awayTeam}
        </AwayOddSelection>
      </div>
    </div>
  ));

  const createChallenge = () => {
    setLoadingState(true);
    const payload = {
      gameType: matchData?.match?.gameType,
      gameFixtureId: matchData?.match?.others?.apiFootballFixtureId,
      walletId: walletValue,
      amount: +valueInput,
      betType: selectedOdd,
      team: selectedClub,
      handicap: +handicap,
    };
    // console.log(payload);
    dispatcher(createChallengeAction({ ...payload }))
      .unwrap()
      .then((originalPromiseResponse) => {
        setLoadingState(false);
        if (!originalPromiseResponse?.createChallenge?.status) {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.createChallenge?.msg
          );
        } else {
          NotificationAlert(
            "success",
            "Successful",
            originalPromiseResponse?.createChallenge?.msg
          );
          setModal(false);
          setHandicap();
          setSelectedClub("");
          setSelectedOdd("");
          setValueInput("");
          navigate("/challenges");
        }
      })
      .catch((err) => {
        setLoadingState(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };
  // console.log(matchData?.match?.others?.apiFootballFixtureId);

  return (
    <div>
      <Row style={{ height: "100%", width: "100%" }} justify="center">
        <Col xs={{ span: 24 }} md={{ span: 20 }}>
          {location?.state?.showOdds === true && (
            <AddBetContainer
              style={{
                width: "fit-content",
                //   position: "fixed",

                //   bottom: 10,
                //   right: 40,
              }}
            >
              <ButtonComponent
                onClick={handleCreateModal}
                text={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AddBet />
                    <span style={{ marginLeft: 5 }}>Create Bet</span>
                  </div>
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "25px",
                  height: 40,
                  margin: 0,
                  padding: "5px 15px",
                }}
              />
              <br />
            </AddBetContainer>
          )}
          <ModalComponent
            // styles={{ height: "500px" }}
            onCancel={handleCreateModal}
            visible={createBetModal}
          >
            <GameDetailsStyle>
              <div
                style={{
                  height: "500px",
                  overflowY: "scroll",
                  padding: "10px",
                }}
              >
                {odds}
              </div>
            </GameDetailsStyle>
          </ModalComponent>
          <ModalComponent onCancel={handleCancelModal} visible={openModal}>
            <div style={{ padding: "10px", textAlign: "center" }}>
              <ConfirmBet>Bet Challenge Details</ConfirmBet>
              <br />
              <ConfirmBetContent>
                <p className="details">
                  You are about to create a bet challenge with the following
                  details:
                </p>
                <br />
                <p className="detailsInfo">
                  Club selection: <span>{selectedClub}</span>
                </p>
                <p className="detailsInfo">
                  Bet Type: <span>{selectedOdd}</span>
                </p>
                <p className="detailsInfo">
                  Select Wallet:
                  <span>
                    <Container style={{ width: "50%", margin: "0 auto" }}>
                      <Dropdown
                        onChange={(e) => setWalletValue(e?.target?.value)}
                      >
                        <Option disabled selected value="">
                          Select a wallet
                        </Option>
                        {wallets?.getUserWallets?.map((wallet: any) => (
                          <Option value={wallet?.walletId}>
                            {wallet?.currencyType === 1
                              ? "Crypto Wallet"
                              : "Fiat Wallet"}
                          </Option>
                        ))}
                      </Dropdown>
                    </Container>
                  </span>
                </p>
                <p className="detailsInfo">
                  Handicap(optional):
                  <span>
                    <Container style={{ width: "50%", margin: "0 auto" }}>
                      <Dropdown onChange={(e) => setHandicap(e?.target?.value)}>
                        <Option disabled selected value="">
                          Select handicap value
                        </Option>
                        {handicapValue.map((value: any) => (
                          <Option value={value}>{value}</Option>
                        ))}
                      </Dropdown>
                    </Container>
                  </span>
                </p>
                <p className="detailsInfo">
                  Bet Amount:
                  <span>
                    <TextInput
                      moreStyles={{
                        width: "70%",
                        alignItems: "center",
                        display: "flex",
                        margin: "0 auto",
                      }}
                      onChange={(e) => setValueInput(e?.target?.value)}
                      placeholder="Enter amount"
                      value={valueInput}
                      inputType="number"
                      name="amount"
                    />
                  </span>
                </p>
                <p className="detailsInfo">
                  Winning: <span> #{formatNumberAmount(+valueInput * 2)}</span>
                </p>
              </ConfirmBetContent>
              <br />
              <div style={{ width: "fit-content", margin: "0 auto" }}>
                <ButtonComponent
                  onClick={() => createChallenge()}
                  disabled={
                    !walletValue || !valueInput || valueInput <= 0
                      ? true
                      : false
                  }
                  text="Create Challenge"
                  loading={loading}
                />
              </div>
            </div>
          </ModalComponent>
          {/* {val === true && ( */}
          <div>
            {/* {lightmode === "dark" ? (
          <div
            key="light"
            id="wg-api-football-fixture"
            data-id={matchData?.match?.others?.apiFootballFixtureId}
            data-host="api-football-v1.p.rapidapi.com"
            // data-refresh="60"
            // data-date="2022-02-11"
            data-key="91aed5f249msh3d311ecb4bb4ef4p1e1656jsnd88a7b77b769"
            data-theme={"false"}
            data-show-errors="true"
            className="api_football_loader"
          ></div>
        ) : ( */}
            <div
              key="dark"
              id="wg-api-football-fixture"
              data-id={
                matchData?.match?.others?.apiFootballFixtureId ||
                matchData?.record?.gameFixtureId
              }
              data-host="api-football-v1.p.rapidapi.com"
              data-refresh="0"
              // data-date="2022-02-11"
              data-key="12638dac42mshcac754191fbf29cp155c3ajsn3bb05a6ea3fd"
              data-theme={"dark"}
              data-show-errors="true"
              className="api_football_loader"
            ></div>
            {/* )} */}
          </div>
          {/* )} */}
          {/* <ChatContainer>
        {location?.state?.showChat === false ? (
          ""
        ) : (
          // <ChatWeb md={{ span: 9 }}>
          <Chat state={location.state} />
          // </ChatWeb>
        )}
      </ChatContainer> */}
        </Col>
      </Row>
    </div>
  );
};

export default GameDetails;
