import { Col, Row, Alert, Button } from "antd";
import { Formik } from "formik";
import * as yup from "yup";

import React from "react";
import { WalletScreen } from "../../../constants/types";
import { ButtonComponent } from "../../common/Button";
import { ModalText } from "../Style";
import TextInput from "../../common/TextInput";
import CurencyDropDown from "../../common/CurrencyDropDown";
import {
  addUserPayOutMethod,
  confirmNGNBankDetails,
  getUserPayoutMethods,
  useAppDispatch,
  WalletSelector,
} from "@buzztechng/vermo-redux";
import { NotificationAlert } from "../../../utils/NotificationAlert";
interface INGNPayment {
  close: (val: WalletScreen) => void;
  next: (val: WalletScreen) => void;
}
interface Values {
  bankName: string;
  accountNumber: string;
}
const NGNPayout: React.FC<INGNPayment> = ({ close, next }) => {
  const dispatcher = useAppDispatch();

  const [hasVerify, setHasVerify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [accountName, setAccountName] = React.useState("Invalid Name");
  const { userPaymentDetails } = WalletSelector() as any;
  const addBankDetails = async (values: Values) => {
    setLoading(true);
    if (hasVerify) {
      let currentValue = {
        bankName: values.bankName.split(",")[0],
        accountNumber: values.accountNumber,
        paymentType: "bank_transfer_ngn",
      };
      let newValue = JSON.stringify(currentValue);
      const data = {
        acceptedPaymentMethodId: userPaymentDetails?.paymentMethod?.data?.id,
        paymentMethodDetails: newValue,
      };
      dispatcher(addUserPayOutMethod(data))
        .unwrap()
        .then((originalPromiseResponse) => {
          setLoading(false);
          if (originalPromiseResponse?.addUserPayOutMethod?.status) {
            dispatcher(getUserPayoutMethods({}));
            NotificationAlert(
              "success",
              `Payment ${originalPromiseResponse?.addUserPayOutMethod?.msg}`
            );
            next(WalletScreen.SELECT_PAYMENT_METHOD);
          }
        })
        .catch((err) => {
          setLoading(false);
          NotificationAlert(
            "error",
            "Error",
            "Could not create account. Try again"
          );
        });
    } else {
      dispatcher(
        confirmNGNBankDetails({
          accountNumber: values.accountNumber,
          bankCode: values.bankName.split(",")[1],
        })
      )
        .unwrap()
        .then((originalPromiseResponse) => {
          setLoading(false);
          if (originalPromiseResponse?.nigeriaConfrimAccountDetails?.status) {
            setHasVerify(true);
            setAccountName(
              originalPromiseResponse?.nigeriaConfrimAccountDetails?.accountName
            );
          } else {
            setError(true);
            setHasVerify(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          NotificationAlert("error", "Error", err.message);
        });
    }
  };
  return (
    <div style={{ textAlign: "start" }}>
      <ModalText>Create NGN Payout Method</ModalText>

      <Formik
        initialValues={{
          bankName: "",
          accountNumber: "",
        }}
        onSubmit={(values: Values) => {
          addBankDetails(values);
        }}
        validationSchema={yup.object().shape({
          bankName: yup.string().required("This field is required"),
          accountNumber: yup
            .string()
            .min(9, "Field must be at least 10 characters")
            .max(10, "Field can't be more than 10")
            .required("This field is required"),
        })}
      >
        {({ values, handleChange, setFieldValue, errors, handleSubmit }) => (
          <>
            <CurencyDropDown
              onChange={setFieldValue}
              name="bankName"
              label="Bank Name"
              field={"bankName"}
              placeholder="Select your Bank"
              error={errors.bankName}
            />
            <TextInput
              required
              inputType="string"
              name="accountNumber"
              placeholder="Enter your Account Number"
              value={values.accountNumber}
              label="Account Number"
              onChange={handleChange}
              error={errors.accountNumber}
            />

            {hasVerify && (
              <TextInput
                required
                isDisabled={true}
                inputType="accountName"
                name="accountName"
                placeholder="Enter your Account Number"
                value={accountName}
                label="Verify Account Name"
              />
            )}
            {error && (
              <Alert
                // message="Account Error"
                description="Could not verify account details. You can choose to re-verify or continue."
                type="error"
                action={
                  <Button
                    onClick={() => {
                      setError(false);
                      setHasVerify(false);
                    }}
                    size="small"
                    danger
                  >
                    Re-Verify
                  </Button>
                }
              />
            )}

            <Row style={{ width: "100%" }} justify="space-between">
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  colorprop={true}
                  onClick={() => close(WalletScreen.CREATE_PAYMENT_METHOD)}
                  text={"Previous"}
                  style={{ marginTop: "10px" }}
                />
              </Col>
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  onClick={handleSubmit}
                  loading={loading}
                  text={hasVerify ? "Create" : "Verify"}
                  // disabled={!selectedMethod && true}
                  style={{ marginTop: "10px" }}
                />
              </Col>
            </Row>
          </>
        )}
      </Formik>

      <br />
    </div>
  );
};

export default NGNPayout;
