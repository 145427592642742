import React from "react";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import { Formik } from "formik";
import * as yup from "yup";

import TextInput from "../common/TextInput";
import {
  registerAction,
  RegisterSelector,
  useAppDispatch,
} from "@buzztechng/vermo-redux";
import { ButtonComponent } from "../common/Button";
import { NotificationAlert } from "../../utils/NotificationAlert";
interface VerifyModalInterface {
  onCancel: () => void;
  visible: boolean;
  onSubmit?: () => void;
  email: any;
}
interface Values {
  otp?: any;
}
const VerifyModal: React.FC<VerifyModalInterface> = ({
  email,
  onCancel,
  onSubmit,
  visible,
}) => {
  const dispatcher = useAppDispatch();

  const [loadingState, setLoading] = React.useState(false);
  const caller = RegisterSelector() as any;
  const [resendLoading, setResendLoading] = React.useState(false);
  const resendVerification = () => {
    setResendLoading(true);
    dispatcher(registerAction({ ...caller?.registerationInfo }))
      .unwrap() //@ts-ignore
      .then((originalPromiseResponse) => {
        NotificationAlert(
          "success",
          "Successful",
          originalPromiseResponse?.createUser?.msg
        );
        setResendLoading(false);
      }) //@ts-ignore
      .catch((err) => {
        setResendLoading(false);
        NotificationAlert("error", "Error", err.message);
        // console.log("err", err.message);
      });
  };
  const verifyUser = (value: Values) => {
    console.log(caller?.registerationInfo as any);
    setLoading(true);
    let userValues = {
      token: String(value.otp),
      ...caller?.registerationInfo,
    };
    // console.log("value")
    dispatcher(registerAction(userValues))
      .unwrap() //@ts-ignore
      .then((originalPromiseResponse) => {
        console.log("originalPromiseResponse", originalPromiseResponse);
        setLoading(false);
        if (originalPromiseResponse?.createUser?.status === false) {
          NotificationAlert(
            "error",
            "Error",
            originalPromiseResponse?.createUser?.msg
          );
        } else {
          onCancel();
          NotificationAlert(
            "success",
            "Successful",
            `${originalPromiseResponse?.createUser?.msg}, kindly login.`
          );
        }
      }) //@ts-ignore
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error", err.message);
        // console.log("err", err.message);
      });
  };

  return (
    <>
      <ModalComponent
        visible={visible}
        //   onCancel={onCancel}
      >
        <div style={{ padding: "1vw", width: "100%" }}>
          <NotificationText>
            An OTP (One Time Password) has been sent to
            <span> {email}</span> , kindly copy and paste below
          </NotificationText>
          <br />
          <Formik
            onSubmit={(values: Values) => {
              verifyUser(values);
            }}
            initialValues={{
              otp: "",
            }}
            validationSchema={yup.object().shape({
              otp: yup.number().required("Please provide your OTP"),
            })}
          >
            {({ values, handleChange, errors, handleSubmit }) => (
              <>
                <TextInput
                  required
                  inputType="number"
                  value={values.otp}
                  name="otp"
                  placeholder="Enter your One Time Password"
                  onChange={handleChange}
                  error={errors.otp}
                  label="One Time Password"
                />
                <ButtonComponent
                  loading={loadingState}
                  onClick={handleSubmit}
                  text={"Verify Account"}
                  style={{ marginTop: "10px" }}
                />
              </>
            )}
          </Formik>
        </div>
        <div style={{ padding: "1vw" }}>
          {!resendLoading ? (
            <NotificationText style={{ textAlign: "start" }}>
              To resend OTP ,
              <span style={{ cursor: "pointer" }} onClick={resendVerification}>
                {" "}
                click here
              </span>
            </NotificationText>
          ) : (
            <NotificationText style={{ textAlign: "start", fontWeight: 400 }}>
              Sending token...
            </NotificationText>
          )}
        </div>
        <br />
      </ModalComponent>
    </>
  );
};

export default VerifyModal;
