import React from "react";
import {
  ChallengesSelector,
  getCreatedChallengeAction,
  useAppDispatch,
} from "@buzztechng/vermo-redux";
import { StyledTable } from "../common/TableComponent";
import moment from "moment";
import { Live } from "./LockedChallenges";
import { Challenger, Selection, Team } from "../common/StyleComponents";
import { formatNumberAmount } from "../../utils/helperfuntions";
import styled from "styled-components";

const MyChallenges = () => {
  const dispatcher = useAppDispatch();
  const { myCreatedChallengeLoading, myCreatedChallenges } =
    ChallengesSelector() as any;
  React.useEffect(() => {
    dispatcher(getCreatedChallengeAction({}));
  }, []);
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, others: any) => {
        return (
          <div>
            <div style={{ display: "flex" }}>
              {moment(text).format("DD.MM.YYYY")}
              {others?.live && <Live>LIVE</Live>}
            </div>
            <div>{moment(text).format("hh:mm a")}</div>
          </div>
        );
      },
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (props: any, others: any) => (
        <Team>
          <div className="content">
            {/* <img className="image" src={props?.homeLogo} alt={props.homeName} /> */}
            <p className="text">{others?.createdByTeam}</p>
          </div>
          <div className="content">
            <p className="text">VS</p>
          </div>
          <div className="content">
            {/* <img className="image" src={props?.awayLogo} alt={props.awayName} /> */}
            <p className="text">{others?.acceptedByTeam}</p>
          </div>
        </Team>
      ),
    },
    {
      title: "Game Type",
      dataIndex: "gameType",
      key: "gameType",
    },

    {
      title: "My Selections",
      dataIndex: "selection",
      key: "selection",
      render: (text: string, other: any) => (
        <div>
          <Selection style={{ border: `${other?.createdByTeam ? "" : ""}` }}>
            {other?.createdByTeam}
          </Selection>
          <Selection
            style={{ border: `${other?.acceptedByTeam ? "none" : ""}` }}
          >
            {other?.acceptedByTeam}
          </Selection>
        </div>
      ),
    },

    {
      title: "Winning",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div>
          <Team>#{formatNumberAmount(+text * 2)}</Team>
        </div>
      ),
    },
    {
      title: "Challenger",
      dataIndex: "challenger",
      key: "challenger",
      render: (text: string, other: any) => (
        <div>
          <Challenger>{other?.createdUser?.name}</Challenger>
        </div>
      ),
    },
  ];
  return (
    <div>
      <StyledTable
        scroll={{ x: 600 }}
        loading={myCreatedChallengeLoading}
        columns={columns}
        dataSource={myCreatedChallenges?.myAvaliableChallenges}
        onRow={(record: any) => {
          return {
            onClick: (e) => {},
          };
        }}
      />
    </div>
  );
};

export default MyChallenges;
