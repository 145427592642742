import React from "react";
import { Outlet } from "react-router";
import styled from "styled-components";
import withAuthentication from "../../hoc/withAuthentication";
import { device } from "../../theme/breakPoints";
import SideMenu from "./SideMenu";
import TopBar from "./TopBar";
import {
  LeagueDetailsSelector,
  getLeaguesDetailsAction,
  useSubscription,
  FOOTBALL_LIVE_FIXTURES_SUBSCRIPTION,
  updateLiveGames,
  useAppDispatch,
  getSupportedCurrency,
  getUserAction,
  getGameTemplate,
  GeneralDataSelector,
  getUsersWalletAction,
  getWalletTransactionsAction,
  getAllChallengeAction,
  getMyLockedChallengeAction,
  getCreatedChallengeAction,
  getNGNBanks,
  getRates,
} from "@buzztechng/vermo-redux";
import LoadingScreen from "./LoadingScreen";
import MobileHeader from "./MobileHeader";
import Drawer from "./Drawer";
import MobileFooter from "./MobileFooter";
const LoggedPage = () => {
  const leagues = LeagueDetailsSelector();
  const dispatcher = useAppDispatch();
  const { data, loading, error } = useSubscription(
    FOOTBALL_LIVE_FIXTURES_SUBSCRIPTION,
    { shouldResubscribe: true }
  ) as any;
  const generalState = GeneralDataSelector();
  const [showDrawer, setShowDrawer] = React.useState(false);
  React.useEffect(() => {
    dispatcher(getUsersWalletAction({}));
    dispatcher(getLeaguesDetailsAction({}));
    dispatcher(getSupportedCurrency({}));
    dispatcher(getUserAction({}));
    dispatcher(getGameTemplate({}));
    dispatcher(getAllChallengeAction({}));
    dispatcher(getMyLockedChallengeAction({}));
    dispatcher(getWalletTransactionsAction({}));
    dispatcher(getCreatedChallengeAction({}));
    dispatcher(getNGNBanks({}));
    dispatcher(getRates({}));
  }, []);
  React.useEffect(() => {
    if (data?.liveFixtures?.length) {
      console.log("data", data?.liveFixtures);
      dispatcher(updateLiveGames(data?.liveFixtures));
    }
  }, [data]);
  const authLoading = generalState.authLoading;
  return (
    <Container>
      {authLoading && <LoadingScreen />}
      <MobileHeader showMenu={showDrawer} setShowMenu={setShowDrawer} />
      <Drawer showDrawer={showDrawer} setDrawerVisible={setShowDrawer} />
      <SideMenu />
      <div style={{ display: "block", width: "100%" }}>
        <TopBar />
        <ContentContainer>
          <Outlet />
        </ContentContainer>
        <MobileFooter />
      </div>
    </Container>
  );
};

export default withAuthentication(LoggedPage);

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background: ${(props) => props.theme.background};
`;

const ContentContainer = styled.div`
  padding: 60px 10px 50px 10px;
  @media ${device.laptop} {
    padding: 1.5vh 2vw;
  }
`;
