/* eslint-disable react/display-name */
import React from "react";
import { useNavigate, Navigate } from "react-router-dom";

const authentication = (WrappedComponent: any) => {
  return (props: any) => {
    if (
      localStorage.token
      //  && localStorage.sub
    ) {
      return <Navigate to="/dashboard" />;
      // } else if (localStorage.token && !localStorage.sub) {
      //   return <Navigate to="/subscription" />;
    }
    return <WrappedComponent {...props} />;
  };
};

export default authentication;
