import { Avatar, Row, Tooltip } from "antd";
import React from "react";
import { ThirdColumn } from "./style";
import Carousel from "../../components/others/Carousel";
import { UserOutlined } from "@ant-design/icons";
import {
  EndContainer,
  MenuItem,
  MidContainer,
  SportTitle,
  StyledDropdown,
  StyledIcon,
  StyledMenu,
  StyledTabs,
  StyledTapPane,
} from "../../components/common/StyleComponents";
import DateInput from "../../components/common/DateInput";
import AllGames from "../../components/Tabs/AllGames";
import LiveGames from "../../components/Tabs/LiveGames";
import FinishedGames from "../../components/Tabs/FinishedGames";
import Scheduled from "../../components/Tabs/Scheduled";
import { formatNumberAmount } from "../../utils/helperfuntions";
import BalanceChart from "../../components/charts/BalanceChart";
import {
  notifications,
  recentUsers,
  lockedGames,
} from "../../constants/dummyData";
import { footballAction, useAppDispatch } from "@buzztechng/vermo-redux";
import AllGamesHomePage from "../../components/Tabs/AllGamesHomepage";
import { ButtonComponent } from "../../components/common/Button";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    dispatcher(
      footballAction({
        currentPage: 0,
        dateFilter: new Date(),
        leagueFilter: 39,
      })
    );
  }, []);
  const navigate = useNavigate();
  const [width, setWidth] = React.useState("") as any;
  const currentNotifications = notifications.map((notify, index) => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}
      >
        <Avatar
          style={{ flexShrink: 0, marginRight: "15px" }}
          size="large"
          src={notify.userPicture}
          alt={`user ${index + 1}`}
          icon={<UserOutlined />}
        />
        <div>
          <p className="notifyName">{notify.name}</p>
          <p className="notifyTimeAndMessaege notifyMessage">
            {notify.message}
          </p>
          <p className="notifyTimeAndMessaege">{notify.time}</p>
        </div>
      </div>
    );
  });
  const lockedChallenge = lockedGames.map((locked, index) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{ marginRight: "15px" }}
            size="small"
            src={locked.img}
            alt={`user ${index + 1}`}
            icon={<UserOutlined />}
          />
          <p className="lockedName">{locked.name}</p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={locked.homeClub} alt="" className="lockedLeagueImage" />
          <p className="lockedName">VS</p>
          <img src={locked.awayClub} alt="" className="lockedLeagueImage" />
        </div>
      </div>
    );
  });
  const recentFollowers = recentUsers.map((user, index) => (
    <Tooltip key={index} title={`user ${index + 1}`} placement="top">
      <Avatar
        style={{ marginRight: "15px", marginBottom: "10px" }}
        size="large"
        src={user}
        alt={`user ${index + 1}`}
        icon={<UserOutlined />}
      />
    </Tooltip>
  ));

  React.useEffect(() => {
    var width = document.getElementById("myId");
    var positionInfo = width?.getBoundingClientRect();
    const absolutewidth = positionInfo?.width;
    if (absolutewidth !== undefined) {
      setWidth(absolutewidth);
    }
  }, []);
  const [sport, setSport] = React.useState("Football");
  const allSports = ["FootBall", "Basketball", "TableTennis", "Boxing", "UFC"];
  const menu = (
    <StyledMenu>
      {allSports?.map((sport: string) => {
        return <MenuItem onClick={() => setSport(sport)}>{sport}</MenuItem>;
      })}
    </StyledMenu>
  );
  const tabRef = React.useRef("1");
  const handleTabChange = (key: any) => {
    tabRef.current = key;
  };
  return (
    <Row justify={"space-between"}>
      <MidContainer
        id="myId"
        // lg={{ span: 16 }} sm={{ span: 24 }}
        lg={{ span: 20 }}
      >
        <Carousel absoluteWidth={width} />
        <br />
        <br />
        <section id="Sports" style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
              marginBottom: "15px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <SportTitle>{sport}</SportTitle>
              <StyledDropdown overlay={menu}>
                <StyledIcon />
              </StyledDropdown>
            </div>
            <div>
              <ButtonComponent
                onClick={() => navigate("/sports")}
                style={{
                  boxShadow: "none",
                  background: "transparent",
                  color: "#e01430",
                }}
                text="Show more"
              />
              {/* <DateInput onChange={(e) => console.log("val", e)} /> */}
              <></>
            </div>
          </div>
          <StyledTabs
            onChange={handleTabChange}
            // defaultActiveKey={tabRef.current}
          >
            <StyledTapPane tab="LIVE Games" key="1">
              <LiveGames />
            </StyledTapPane>
            <StyledTapPane tab="All Games" key="2">
              <AllGamesHomePage />
            </StyledTapPane>
          </StyledTabs>
        </section>
      </MidContainer>
      <EndContainer lg={{ span: 7 }} style={{ display: "none" }}>
        <ThirdColumn>
          {/* <div className="flexer" style={{ alignItems: "baseline" }}>
            <p className="balance">Your balance</p>
            <p className="balanceValue">#{formatNumberAmount(0)}</p>
          </div>
          <BalanceChart />
          <br /> */}
          <div className="lockedChallenge">
            <p className="title">Locked challenges</p>
            <br />
            {lockedChallenge}
          </div>
          <br />
          <p style={{ marginBottom: "15px" }} className="title">
            Recent followers
          </p>
          {recentFollowers}
          <br />
          <br />
          <p style={{ marginBottom: "15px" }} className="title">
            Notifications
          </p>
          {currentNotifications}
        </ThirdColumn>
      </EndContainer>
    </Row>
  );
};

export default Dashboard;
