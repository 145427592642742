import {
  getAllChallengeAction,
  getMyLockedChallengeAction,
} from "@buzztechng/vermo-redux";
import React from "react";
import { Selection, Team } from "../common/StyleComponents";
import { StyledTable } from "../common/TableComponent";
import { ChallengesSelector, useAppDispatch } from "@buzztechng/vermo-redux";
import moment from "moment";
import { formatNumberAmount } from "../../utils/helperfuntions";
import AcceptChallengeModal from "../Modals/AcceptChallengeModal";

const AvailableChallenges = () => {
  const { allChallengeLoading, myAvailableChallanges } =
    ChallengesSelector() as any;
  const [showModal, setShowModal] = React.useState(false);
  const [currentData, setCurrentData] = React.useState({}) as any;
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, others: any) => (
        <div style={{ display: "flex" }}>
          {moment(text).format("DD.MM.YYYY")}
        </div>
      ),
    },
    {
      title: "Game Type",
      dataIndex: "gameType",
      key: "gameType",
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (props: any, all: any) => (
        <Team>
          <div className="content">
            {/* <img className="image" src={props?.homeLogo} alt={props.homeName} /> */}
            <p className="text" style={{ textAlign: "center" }}>
              {all.createdByTeam}
            </p>
          </div>
          <p className="text">vs</p>
          <div className="content">
            {/* <img className="image" src={props?.awayLogo} alt={props.awayName} /> */}
            <p className="text">{all.acceptedByTeam}</p>
          </div>
        </Team>
      ),
    },
    {
      title: "Bet Type",
      dataIndex: "betType",
      key: "betType",
    },

    {
      title: "Challenge Selection",
      dataIndex: "selection",
      key: "selection",
      render: (text: string, all: any) => (
        <div>
          <Selection
            style={{
              border: `${all.createdByTeam && ""}`,
            }}
          >
            {all.createdByTeam}
          </Selection>
          <Selection style={{ border: `${text !== "AWAY" ? "none" : ""}` }}>
            {all.acceptedByTeam}
          </Selection>
        </div>
      ),
    },

    {
      title: "Winning",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div>
          <Team>#{formatNumberAmount(+text * 2)}</Team>
        </div>
      ),
    },
  ];

  const dispatcher = useAppDispatch();
  React.useEffect(() => {
    dispatcher(getAllChallengeAction({}));
  }, []);

  return (
    <>
      {showModal && (
        <AcceptChallengeModal
          data={currentData}
          onCancel={() => {
            setShowModal(!showModal);
            dispatcher(getAllChallengeAction({}));
            dispatcher(getMyLockedChallengeAction({}));
          }}
          visible={showModal}
        />
      )}
      <div>
        <StyledTable
          scroll={{ x: 600 }}
          loading={allChallengeLoading}
          columns={columns}
          dataSource={myAvailableChallanges?.getChallenges}
          onRow={(record: any) => {
            return {
              onClick: (e) => {
                console.log("records", record?.live);
                setShowModal(!showModal);
                setCurrentData(record);
              },
            };
          }}
        />
      </div>
    </>
  );
};

export default AvailableChallenges;
