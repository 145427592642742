import {
  getAllChallengeAction,
  getMyLockedChallengeAction,
  useAppDispatch,
} from "@buzztechng/vermo-redux";
import { Row } from "antd";
import React from "react";
import { ButtonComponent } from "../../components/common/Button";
import DateInput from "../../components/common/DateInput";
import DropDown from "../../components/common/DropDown";
import {
  EndContainer,
  FilterContainer,
  MidContainer,
  PageTitle,
  StyledTabs,
  StyledTapPane,
} from "../../components/common/StyleComponents";
import AvailableChallenges from "../../components/Tabs/AvailableChallenges";
import ChallengeHistory from "../../components/Tabs/ChallengeHistory";
import FriendsChallenge from "../../components/Tabs/FriendsChallenge";
import LockedChallenges from "../../components/Tabs/LockedChallenges";
import MyChallenges from "../../components/Tabs/MyChallenges";

const Challenges = () => {
  const dispatcher = useAppDispatch();

  const allSports = ["FootBall", "Basketball", "TableTennis", "Boxing", "UFC"];
  const divisions = ["La liga", "Bundesliga", "French Open"];
  const country = ["Angola", "Spain", "Sample", "Sample"];
  React.useEffect(() => {
    dispatcher(getAllChallengeAction({}));
    dispatcher(getMyLockedChallengeAction({}));
  }, []);
  return (
    <>
      <PageTitle>
        <h1>Challenges</h1>
        <h2>Manage all challenges.</h2>
        <br />
        <Row justify="space-between">
          <MidContainer lg={{ span: 17 }} sm={{ span: 24 }}>
            <StyledTabs defaultActiveKey="1">
              <StyledTapPane tab="My Challenges" key="1">
                <MyChallenges />
              </StyledTapPane>
              <StyledTapPane tab="Friend's Challenges" key="2">
                <FriendsChallenge />
              </StyledTapPane>
              <StyledTapPane tab="Locked Challenges" key="3">
                <LockedChallenges />
              </StyledTapPane>
              <StyledTapPane tab="Available Challenges" key="4">
                <AvailableChallenges />
              </StyledTapPane>
              <StyledTapPane tab="Challenge History" key="5">
                <ChallengeHistory />
              </StyledTapPane>
            </StyledTabs>
          </MidContainer>
          <EndContainer md={{ span: 6 }}>
            <FilterContainer>
              <p className="filter"> Filter all challenges</p>
              <br />
              <DropDown
                placeholder="Select game"
                values={allSports}
                label="Games"
              />
              <DropDown
                placeholder="Select country"
                values={country}
                label="Country"
              />
              <DropDown
                placeholder="Select division"
                values={divisions}
                label="Division"
              />

              <DateInput
                label="Date"
                style={{ marginBottom: 15 }}
                onChange={(e) => console.log("val", e)}
              />
              <br />
              <ButtonComponent style={{}} text="Filter available challenges" />
            </FilterContainer>
          </EndContainer>
        </Row>
      </PageTitle>
    </>
  );
};

export default Challenges;
