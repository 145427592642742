import { Modal } from "antd";
import React from "react";
import styled from "styled-components";

interface ModalType {
  onCancel?: () => void;
  visible: boolean;
  styles?: any;
}

export const ModalComponent: React.FC<ModalType> = ({
  onCancel,
  children,
  visible,
  styles,
}) => {
  //   const visible = useSelector((state) => state.otpModalReducer.modalVisible);
  return (
    <StyledModal
      bodyStyle={{ ...styles }}
      centered={true}
      closable={false}
      footer={false}
      onCancel={onCancel}
      visible={visible}
      style={{ padding: "none" }}
    >
      {children}
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: 50vw;
  padding: none;
  .ant-modal-body {
    padding: 10px 0;
    background: ${(props) => props.theme.foreground};
    border: 0px solid white;
    box-shadow: ${(props) => props.theme.subtleShadow};
    border-radius: 15px;
  }
  .ant-modal-content {
    border-radius: 15px;
  }
  .wrapper {
    max-height: 400px;
  }
`;
