import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { PageTitle } from "../../components/common/StyleComponents";
import TextInput from "../../components/common/TextInput";
import UserModal from "../../components/Modals/UserModal";
import {
  getAllUsersAction,
  GeneralDataSelector,
  AllUserSelector,
  useAppDispatch,
} from "@buzztechng/vermo-redux";
import { StyledTable } from "../../components/common/TableComponent";

const User = () => {
  const dispatcher = useAppDispatch();
  const [name, setName] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const { allUsersLoading } = GeneralDataSelector() as any;
  const allUsersSelector = AllUserSelector() as any;
  useEffect(() => {
    dispatcher(getAllUsersAction({}));
  });
  const handleNameChange = (value: any) => {
    setName(value);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (text: any) => <div>{text === "F" ? "Female" : "Male"}</div>,
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "genpointsder",
      render: (text: any) => <div>0 points</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];
  return (
    <>
      <UserModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        actionType={"add"}
      />
      <PageTitle>
        <h1>Users</h1>
        <h2>Make friends by following other users on Vermo</h2>
      </PageTitle>
      <br />
      <Row>
        <Col xs={24} md={12}>
          <TextInput
            value={name}
            inputType="text"
            name="name"
            label={"Filter users list"}
            moreStyles={{ borderRadius: "20px" }}
            placeholder="Type a user information you would like to search..."
            onChange={(e) => handleNameChange(e.target.value)}
          />
        </Col>
      </Row>
      <br />
      <PageTitle>
        <h2>
          Filter and follow trending users based on winning streaks or other
          criteria.
        </h2>
      </PageTitle>
      <StyledTable
        scroll={{ x: 600 }}
        columns={columns}
        dataSource={allUsersSelector?.getUsers}
        loading={allUsersLoading}
      />
    </>
  );
};

export default User;
