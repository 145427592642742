import React from "react";
import { formatNumberAmount } from "../../utils/helperfuntions";
import { ConfirmBet, ConfirmBetContent } from "../../Views/GameDetails/Style";
import { ButtonComponent } from "../common/Button";
import { ModalComponent } from "../common/ModalComponent";
import { Challenger, Selection, Team } from "../common/StyleComponents";
import { StyledTable } from "../common/TableComponent";

const FriendsChallenge = () => {
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: string, others: any) => (
        <div style={{ display: "flex" }}>{text}</div>
      ),
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (props: any) => (
        <Team>
          <div className="content">
            <img className="image" src={props?.homeLogo} alt={props.homeName} />
            <p className="text">{props.homeName}</p>
          </div>
          <div className="content">
            <img className="image" src={props?.awayLogo} alt={props.awayName} />
            <p className="text">{props.awayName}</p>
          </div>
        </Team>
      ),
    },
    {
      title: "Tournament",
      dataIndex: "tournament",
      key: "tournament",
      // render: (text: string) => (
      //   <div>
      //     <Text fontSize={{ base: "xs", md: "small" }} fontWeight="bold">
      //       {moment(text).format("MMM D, YYYY")}
      //     </Text>
      //     <Text fontSize={{ base: "xs", md: "xs" }} fontWeight="normal">
      //       Submitted On
      //     </Text>
      //   </div>
      // ),
    },

    {
      title: "Challenger's Selection",
      dataIndex: "selection",
      key: "selection",
      render: (text: string) => (
        <div>
          <Selection style={{ border: `${text !== "HOME" ? "none" : ""}` }}>
            HOME
          </Selection>
          <Selection style={{ border: `${text !== "AWAY" ? "none" : ""}` }}>
            AWAY
          </Selection>
        </div>
      ),
    },

    {
      title: "Winning",
      dataIndex: "winning",
      key: "winning",
      // render: (text: string) => (
      //   <div>
      //     <Text fontSize={{ base: "xs", md: "small" }} fontWeight="bold">
      //       {moment(text).format("MMM D, YYYY")}
      //     </Text>
      //     <Text fontSize={{ base: "xs", md: "xs" }} fontWeight="normal">
      //       Submitted On
      //     </Text>
      //   </div>
      // ),
    },
    {
      title: "Challenger",
      dataIndex: "challenger",
      key: "challenger",
      render: (text: string) => (
        <div>
          <Challenger>{text}</Challenger>
        </div>
      ),
    },
  ];
  const data = [
    {
      key: 1,
      date: "30.10.2021",
      winning: "#30,000",
      selection: "HOME",
      live: true,
      challenger: "Harry Kane",
      team: {
        homeName: "Barcelona F.C",
        homeLogo: "https://cdn-icons-png.flaticon.com/512/738/738658.png",
        awayName: "AC Milan",
        awayLogo: "https://cdn-icons-png.flaticon.com/512/824/824743.png",
      },
      tournament: "La Liga",
    },
    {
      key: 2,
      date: "12.10.2021",
      winning: "#30,000",
      selection: "AWAY",
      challenger: "Adewole Kola",
      team: {
        homeName: "Liverpool F.C",
        homeLogo: "https://cdn-icons-png.flaticon.com/512/1385/1385008.png",
        awayName: "Milano FC",
        awayLogo: "https://cdn-icons-png.flaticon.com/512/824/824725.png",
      },
      tournament: "Serie A",
    },
    {
      key: 3,
      date: "30.10.2021",
      winning: "#10,000",
      selection: "HOME",
      live: false,
      challenger: "Harry Kane",
      team: {
        homeName: "Chelsea F.C",
        homeLogo: "https://cdn-icons-png.flaticon.com/512/824/824728.png",
        awayName: "AC Milan",
        awayLogo: "https://cdn-icons-png.flaticon.com/512/824/824743.png",
      },
      tournament: "La Liga",
    },
    {
      key: 4,
      date: "12.10.2021",
      winning: "#10,000",
      selection: "AWAY",
      challenger: "Lampard Kane",
      live: true,
      team: {
        homeName: "Liverpool F.C",
        homeLogo: "https://cdn-icons-png.flaticon.com/512/1385/1385008.png",
        awayName: "Milano FC",
        awayLogo: "https://cdn-icons-png.flaticon.com/512/824/824725.png",
      },
      tournament: "Serie A",
    },
    {
      key: 5,
      date: "30.10.2021",
      winning: "#30,000",
      selection: "HOME",
      challenger: "Wane Bruce",
      live: true,
      team: {
        homeName: "Barcelona F.C",
        homeLogo: "https://cdn-icons-png.flaticon.com/512/738/738658.png",
        awayName: "AC Milan",
        awayLogo: "https://cdn-icons-png.flaticon.com/512/824/824743.png",
      },
      tournament: "La Liga",
    },
    {
      key: 6,
      date: "12.10.2021",
      winning: "#30,000",
      challenger: "Kingsley Kane",
      selection: "AWAY",
      team: {
        homeName: "Liverpool F.C",
        homeLogo: "https://cdn-icons-png.flaticon.com/512/1385/1385008.png",
        awayName: "Milano FC",
        awayLogo: "https://cdn-icons-png.flaticon.com/512/824/824725.png",
      },
      tournament: "Serie A",
    },
  ];
  const [challengeDetails, setChallengeDetails] = React.useState() as any;
  const [openModal, setModal] = React.useState(false);
  const handleCancelModal = () => {
    setChallengeDetails("");
    setModal(false);
  };
  console.log("challenge details", challengeDetails);
  return (
    <div>
      <StyledTable
        scroll={{ x: 600 }}
        columns={columns}
        dataSource={[]}
        onRow={(record: any) => {
          return {
            onClick: (e) => {
              setModal(true);
              setChallengeDetails(record);
            },
          };
        }}
      />
      <ModalComponent onCancel={handleCancelModal} visible={openModal}>
        <div style={{ padding: "10px" }}>
          <ConfirmBet>Bet Challenge Details</ConfirmBet>
          <br />
          <ConfirmBetContent>
            <p className="details">
              You are about to accept a bet challenge with the following
              details:
            </p>
            <br />
            <p className="detailsInfo">
              Bet Type: <span>Sample Type</span>
            </p>
            <p className="detailsInfo">
              Winning: <span> {challengeDetails?.winning}</span>
            </p>

            <p className="detailsInfo">
              Club selection:{" "}
              <span>
                {challengeDetails?.selection === "HOME"
                  ? challengeDetails?.team?.awayName
                  : challengeDetails?.team?.homeName}
              </span>
            </p>
          </ConfirmBetContent>
          <br />
          <div style={{ width: "fit-content", margin: "0 auto" }}>
            <ButtonComponent text="Accept Challenge" />
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default FriendsChallenge;
