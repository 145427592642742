import React from "react";
import { Avatar, Tooltip } from "antd";
import styled from "styled-components";
import { device } from "../../../theme/breakPoints";
import { desktop } from "../../../theme/fontSize";
import { useNavigate } from "react-router-dom";

interface iFootball {
  matchDets: any;
  data?: any;
}

const PairSport = ({ matchDets, data }: iFootball) => {
  const navigate = useNavigate();
  const dualSports = matchDets?.map((match: any) => (
    <Container
      onClick={() =>
        navigate("/details", {
          state: {
            showChat: false,
            showOdds: match?.liveStatus === true ? false : true,
            match,
            data,
          },
        })
      }
      active={match?.liveStatus ? true : false}
    >
      <div className="container">
        <div className="minWrapper durationWrapper">
          <div>
            <p className="time">{match?.time}</p>
            <p className="date">{match?.date}</p>
          </div>
          {match.liveStatus && (
            <div className="live">LIVE {match?.gameTime}"</div>
          )}
        </div>
        <div
          className="maxWrapper"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="percentWrapper">
            <img
              className="
  leagueimg"
              src={match?.homeTeamLogo}
              alt="home"
            />
            <p className="teamName">{match?.homeTeam}</p>
          </div>
          <div className="scoreContent">
            {match.liveStatus === true
              ? `${match?.homeScore ?? 0}:${match?.awayScore ?? 0}`
              : "VS"}
          </div>
          <div className="percentWrapper">
            <p className="teamName"> {match?.awayTeam}</p>
            <img
              className="
  leagueimg"
              src={match?.awayTeamLogo}
              alt="away"
            />
          </div>
        </div>
        <div
          className="showMobile minWrapper"
          style={{ display: "flex", alignItems: "center" }}
        >
          {/* <p className="date" style={{ marginRight: "10px" }}>
            {match?.inplay?.tips}+ games
          </p> */}

          <Avatar.Group size={{ xs: 24, sm: 32, md: 40 }} maxCount={2}>
            {match?.inplay?.players?.map((pic: any, id: any) => {
              console.log("pics", pic);
              return (
                <Tooltip title="user" placement="top">
                  <Avatar key={id} alt={`user ${id + 1}`} src={pic} />
                </Tooltip>
              );
            })}
          </Avatar.Group>
        </div>
      </div>
    </Container>
  ));
  return <>{dualSports}</>;
};

export default PairSport;

interface Active {
  active?: boolean;
}

const Container = styled.div<Active>`
  padding: 17px 0;
  border-radius: 7px;
  cursor: pointer;
  background: ${(props) => props.theme.foreground};
  box-shadow: ${(props) => props.theme.subtleShadow};
  margin: 15px 0;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-left: ${(props) =>
      `4px solid ${props.active ? props.theme.accentPrimary : "transparent"}`};
    padding: 0 10px;
  }
  .time {
    margin: 0;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.xSmall};
    font-weight: 500;
  }
  .date {
    margin: 0;
    font-weight: 500;
    color: ${(props) => props.theme.mutedColorTertiary};
    font-size: ${desktop.xSmall};
  }
  .live {
    margin-left: 0px;
    color: ${(props) => props.theme.textColor};
    border-radius: 4px;
    font-weight: 600;
    padding: 1px 5px;
    width: fit-content;
    font-size: ${desktop.xSmall};
    background: ${(props) => props.theme.accentPrimary};
  }
  .leagueimg {
    height: clamp(16px, 1.4vw, 25px);
    margin-right: 15px;
  }
  .teamName {
    margin: 0;
    width: 80%;
    font-weight: 500;
    color: ${(props) => props.theme.textColor};
    font-size: ${desktop.small};
  }
  .scoreContent {
    padding: 10px;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 50%;
    background: ${(props) => props.theme.mutedColorSecondary};
    color: ${(props) => props.theme.textColor};
    font-weight: 600;
    font-size: ${desktop.small};
    text-align: center;
    margin: 0 10%;
  }
  .minWrapper {
    flex-wrap: wrap;
    width: 20%;
    /* background-color: red; */
  }
  .maxWrapper {
    flex-wrap: wrap;
    width: 80%;
    /* background-color: blue; */
    /* margin-left */
  }
  .percentWrapper {
    width: 30%;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
  }
  .durationWrapper {
    display: block;
  }
  .showMobile {
    display: none !important;
  }
  @media ${device.tablet} {
    .teamName {
      margin: 0;
      width: 80%;
      font-weight: 500;
      color: ${(props) => props.theme.textColor};
      font-size: ${desktop.small};
    }
    .durationWrapper {
      display: flex;
      align-items: center;
    }
    .live {
      margin-left: 15px;
    }
    .maxWrapper {
      width: 60%;
    }
    .percentWrapper {
      width: 30%;
    }
    .showMobile {
      display: block !important;
    }
  }
`;
