import { Row, Spin } from "antd";
import React from "react";
import { ButtonComponent } from "../../components/common/Button";
import { PageTitle } from "../../components/common/StyleComponents";
import { ComingSoon, Loading, WalletCard, WalletContainer } from "./styles";
import { SiBitcoincash } from "react-icons/si";
import { BsCashCoin } from "react-icons/bs";
import { ImStatsDots } from "react-icons/im";
import { formatNumberAmount } from "../../utils/helperfuntions";
import CreateWalletModal from "../../components/Modals/CreateWalletModal";
import FundWalletModal from "../../components/Modals/FundWalletModal";
import {
  useAppDispatch,
  UserWalletSelector,
  WalletTransactionSelector,
  getUsersWalletAction,
  getTransactionStats,
  TransactionStatSelector,
  getWalletTransactionsAction,
  WalletSelector,
} from "@buzztechng/vermo-redux";
import { StyledTable } from "../../components/common/TableComponent";
import moment from "moment";
import WalletDetailsModal from "../../components/Modals/WalletDetailsModal";
import SuccessfulPayment from "../../components/Modals/Payments/SuccessfulPayment";
import AccountProcessor from "../../components/Modals/Payments/AccountProcessor";
interface ArgsInterface {
  walletId: any;
  walletName?: any;
}
const Wallet = () => {
  const dispatcher = useAppDispatch();
  const { walletTransactions } = WalletTransactionSelector() as any;
  const { transactionsLoading, userWalletLoading } = WalletSelector() as any;
  const [showDetailsModal, setShowModal] = React.useState(false);
  const [currentData, setCurrentData] = React.useState({}) as any;

  React.useEffect(() => {
    dispatcher(getUsersWalletAction({}));
    dispatcher(getTransactionStats({}));
    dispatcher(getWalletTransactionsAction({}));
  }, []);
  // console.log("transaction", transactionHistory?.walletTransactions);
  const columns = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any) => <div>#{formatNumberAmount(text)}</div>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <div>{moment(text).format("MMM D, YYYY")}</div>,
    },
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: any) => <div>{moment(text).format("HH:MM A")}</div>,
    },
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      // render: (text: any) => <div>{moment(text).format("MMM D, YYYY")}</div>,
    },
    {
      title: "Wallet Type",
      dataIndex: "walletUser",
      key: "walletUser",
      render: (text: any, wallet: any) => (
        <div>{wallet?.walletUser?.currency?.kind}</div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const wallet = UserWalletSelector() as any;
  // console.log("wallet", wallet);
  const transactionStats = TransactionStatSelector() as any;
  // const walletTransaction = WalletTransactionSelector();

  const [createWalletModal, setCreateWalletModal] = React.useState(false);
  const [fundWalletModal, setFundWalletModal] = React.useState(false);
  const [walletId, setWalletId] = React.useState("");
  const [walletName, setWalletName] = React.useState("");
  const [success, setSuccessPayment] = React.useState<boolean>(false);
  const handleOpenFundWalletModal = (arg: ArgsInterface) => {
    setWalletId(arg?.walletId);
    setFundWalletModal(true);
    setWalletName(arg?.walletName);
  };
  const [showPaymentProcess, setShowPaymentProcess] =
    React.useState<boolean>(false);
  const walletData = wallet?.getUserWallets?.map((value: any) => {
    const newData = {
      icon: (
        <>
          {/* <SiBitcoincash />  */}
          <BsCashCoin />
        </>
      ),
      id: value?.walletId,
      balance: value?.walletBalance,
      comingSoon: value?.currencyType === 2,
      currency: "usd",
      type: value?.currencyType === 1 ? "Crypto" : "Fiat",
    };
    return newData;
  });
  const wallets = walletData?.map((data: any) => (
    <WalletContainer lg={8} xs={24}>
      <WalletCard
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginBottom: "30px",
        }}
        iconColor=""
        changeProp={false}
      >
        {data?.comingSoon && (
          <ComingSoon>
            <h2 className="text">Coming soon...</h2>
          </ComingSoon>
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="icon">{data?.icon}</div>
          <p className="title">Vermo-Wallet</p>
        </div>
        <br />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div>
            <p className="title">Your Vermo Balance</p>
            <h2 className="price">
              {formatNumberAmount(data?.balance)} <span>{data?.currency}</span>
            </h2>
          </div>
          <div
            style={{
              width: "fit-content",
              marginLeft: "auto",
              // marginTop: "auto",
            }}
          >
            <ButtonComponent
              onClick={() =>
                handleOpenFundWalletModal({
                  walletId: "",
                  walletName: data?.type,
                })
              }
              style={{
                fontWeight: 600,
              }}
              text="Fund"
            />
          </div>
        </div>
      </WalletCard>
    </WalletContainer>
  ));

  return (
    <>
      <WalletDetailsModal
        visible={showDetailsModal}
        details={currentData}
        onCancel={() => setShowModal(!showDetailsModal)}
      />
      {showPaymentProcess && (
        <AccountProcessor
          visible={showPaymentProcess}
          onCancel={() => setShowPaymentProcess(false)}
        />
      )}
      {/* <SuccessfulPayment
        visible={success}
        onCancel={() => {
          setSuccessPayment(false);
        }}
      /> */}
      <CreateWalletModal
        visible={createWalletModal}
        onCancel={() => setCreateWalletModal(false)}
      />
      <FundWalletModal
        walletType={walletName}
        onCancel={() => setFundWalletModal(false)}
        visible={fundWalletModal}
        walletValue={walletId}
      />
      <PageTitle>
        <h1>Wallet</h1>
        <h2>
          Create wallet, fund wallet and monitor transactions on your fiat and
          cryptocurrency wallets.
        </h2>
      </PageTitle>
      <br />

      <div style={{ display: "flex" }}>
        {wallet?.getUserWallets?.length < 2 && (
          <div style={{ width: "fit-content", marginRight: "15px" }}>
            <ButtonComponent
              onClick={() => setCreateWalletModal(true)}
              style={{
                fontWeight: 600,
              }}
              text="Create Wallet"
            />
          </div>
        )}

        {wallet?.getUserWallets?.length > 0 && (
          <div style={{ width: "fit-content" }}>
            <ButtonComponent
              onClick={() => setShowPaymentProcess(true)}
              style={{
                fontWeight: 600,
              }}
              text="Payout"
            />
          </div>
        )}
      </div>
      <br />
      <br />
      <Row style={{ width: "100%" }}>
        {wallet?.getUserWallets?.length > 0 && wallets}
        {userWalletLoading && (
          <Loading>
            <Spin size="small" />

            <div>
              <p>Loading wallet details, please wait... </p>
            </div>
          </Loading>
        )}
        <WalletContainer lg={8} xs={24}>
          <WalletCard
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            titleColor=""
            changeProp={false}
          >
            <ComingSoon>
              <h2 className="text">Coming soon...</h2>
            </ComingSoon>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="icon">
                <BsCashCoin />
              </div>
              <div>
                <p className="title">Fiat Wallet</p>
              </div>
            </div>
            <br />
            <div style={{ display: "flex" }}>
              <div>
                <p className="title">Your fiat balance</p>
                <h2 className="price">
                  {formatNumberAmount(0)} <span>usd ($)</span>
                </h2>
              </div>
              <div
                style={{
                  width: "fit-content",
                  marginLeft: "auto",
                  // marginTop: "auto",
                }}
              >
                <ButtonComponent
                  style={{
                    fontWeight: 600,
                  }}
                  text="Fund"
                />
              </div>
            </div>
          </WalletCard>
        </WalletContainer>
        {wallet?.getUserWallets?.length > 0 && (
          <WalletContainer lg={8} xs={24}>
            <WalletCard
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              iconColor="#5db36d"
              titleColor="white"
              changeProp={true}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="icon">
                  <ImStatsDots />
                </div>
                <p className="title">Payment Metrics</p>
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                  <p className="title">Inflow</p>
                  <h2 className="price">
                    {formatNumberAmount(
                      transactionStats?.transactionStats?.credit
                    )}
                    <span>usd($)</span>
                  </h2>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <p className="title">Outflow</p>

                  <h2 className="price">
                    {formatNumberAmount(
                      transactionStats?.transactionStats?.debit
                    )}{" "}
                    <span>usd ($)</span>
                  </h2>
                </div>
              </div>
            </WalletCard>
          </WalletContainer>
        )}
      </Row>
      <br />
      <br />
      <PageTitle>
        {/* <h1>Wallet Transaction History</h1> */}
        <h2>Vermo Wallet Transactions History</h2>
      </PageTitle>
      <br />
      <StyledTable
        scroll={{ x: 600 }}
        loading={transactionsLoading}
        columns={columns}
        dataSource={walletTransactions}
        onRow={(record: any) => {
          return {
            onClick: (e) => {
              console.log("records", record);
              // record.description !== "TOP UP" &&
              setShowModal(!showDetailsModal);
              setCurrentData(record);
            },
          };
        }}
      />
    </>
  );
};

export default Wallet;
