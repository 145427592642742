import React from "react";
import { Progress } from "antd";
import { ModalComponent } from "../../common/ModalComponent";
import { DetailsText, Divider, SuccessText } from "../Style";
import { ButtonComponent } from "../../common/Button";
import { formatNumberAmount } from "../../../utils/helperfuntions";
import {
  GeneralDataSelector,
  setUserPaymentDetails,
  useAppDispatch,
  WalletSelector,
} from "@buzztechng/vermo-redux";
interface ModalInterface {
  // onCancel: () => void;
  next: () => void;
  visible?: boolean;
  data?: any;
}
const SuccessfulPayment: React.FC<ModalInterface> = ({
  next,
  visible,
  data,
}) => {
  const dispatcher = useAppDispatch();
  const { userPaymentDetails, getRates } = WalletSelector() as any;
  const [value, setValue] = React.useState(10);
  const valueParsed = JSON.parse(
    userPaymentDetails?.userPayment?.paymentMethodDetails
  );
  const generalState = GeneralDataSelector() as any;

  const userDetails = generalState?.userDetails?.me;
  console.log(valueParsed);
  React.useEffect(() => {
    if (value < 100) {
      let timer = setTimeout(() => {
        setValue(value + 10);
      }, 90);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [value]);
  return (
    <>
      {/* <ModalComponent visible={visible} onCancel={() => {}}> */}
      <div style={{ padding: "1vw 2vw", width: "100%", textAlign: "center" }}>
        <Progress
          type="circle"
          width={80}
          percent={value}
          style={{ marginBottom: "10px" }}
        />
        <SuccessText>Payout Successful!</SuccessText>
        <br />
        <Divider />
        <br />
        <DetailsText>
          Your payout with the following details was successful. You should
          receive your payment soon. Thank you.
        </DetailsText>
        <br />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DetailsText>Amount :</DetailsText>
          <DetailsText>
            ${formatNumberAmount(userPaymentDetails?.amount)}
          </DetailsText>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DetailsText>Amount in local currency :</DetailsText>
          <DetailsText>
            #{""}
            {formatNumberAmount(
              +userPaymentDetails?.payOutResponse?.payOutAmount *
                +getRates[userDetails?.defaultCurrency]
            )}
          </DetailsText>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DetailsText>Bank Name :</DetailsText>
          <DetailsText>{valueParsed?.bankName}</DetailsText>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DetailsText>Bank Account Number :</DetailsText>
          <DetailsText>{valueParsed?.accountNumber}</DetailsText>
        </div>
        <br />
        <ButtonComponent
          onClick={() => {
            next();
            dispatcher(setUserPaymentDetails(""));
          }}
          text={"Okay"}
          style={{ margin: " 0 0 15px 0" }}
        />
      </div>
      {/* </ModalComponent> */}
    </>
  );
};

export default SuccessfulPayment;
