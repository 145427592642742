import React from "react";
import { ThemeProvider } from "styled-components";
import { Routes, Route, Navigate } from "react-router";
import "antd/dist/antd.css";
import Login from "./Views/Login";
import LoggedPage from "./components/layouts/LoggedPage";
import { routes } from "./routes";
import Register from "./Views/Register/Index";
import { mode, useAppSelector } from "@buzztechng/vermo-redux";
import Subscription from "./Views/Subscription/Index";
import { darkTheme, GlobalStyle, lightTheme } from "./theme/theme";
import ForgotPassword from "./Views/ForgotPassword";
import Error from "./Views/Error";
import withAuthentication from "./hoc/withAuthentication";

function App() {
  const lightmode = useAppSelector(mode);

  return (
    <ThemeProvider
      // theme={lightTheme}
      theme={lightmode !== "dark" ? darkTheme : lightTheme}
    >
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Navigate to={"/dashboard"} />} />
        <Route path="/error" element={<Error />} />
        <Route path="/" element={<LoggedPage />}>
          {routes.map((route, index) => (
            <Route path={route.path} element={<route.element />} key={index} />
          ))}
        </Route>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="*" element={<Navigate to="/error" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
