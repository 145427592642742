import React from "react";
import { ButtonComponent } from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import {
  AuthSubtitle,
  AuthTitle,
  AuthWrapper,
} from "../../components/common/StyleComponents";
import TextInput from "../../components/common/TextInput";
import Auth from "../../components/layouts/Auth";
import { Formik } from "formik";
import * as yup from "yup";
import { validationSchema } from "../../utils/validationSchema";
import ResetPassword from "../../components/Modals/ResetPassword";
import {
  forgotPasswordAction,
  useAppDispatch,
  setRegistrationInfo,
} from "@buzztechng/vermo-redux";
import { NotificationAlert } from "../../utils/NotificationAlert";
interface Values {
  email: string;
}

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatcher = useAppDispatch();

  const [loading, setLoading] = React.useState(false);
  const [resetModal, setResetModal] = React.useState(false);

  const forgotPassword = (values: Values) => {
    setLoading(true);
    let userValues = {
      email: values.email,
    };
    dispatcher(setRegistrationInfo(userValues));
    dispatcher(forgotPasswordAction(userValues))
      .unwrap()
      .then((originalPromiseResponse) => {
        console.log("response", originalPromiseResponse);
        setLoading(false);
        if (originalPromiseResponse?.forgotPassword?.status === false) {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.forgotPassword?.msg
          );
        } else {
          setResetModal(true);
          NotificationAlert(
            "success",
            "Success",
            originalPromiseResponse?.forgotPassword?.msg
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error", err.message);
        // console.log("err", err.message);
      });
  };
  return (
    <Auth>
      <ResetPassword
        // email={email}
        onCancel={() => setResetModal(false)}
        visible={resetModal}
      />
      <AuthWrapper>
        <AuthTitle>Reset your Vermo account </AuthTitle>
        <AuthSubtitle>
          Remebered your credentials?
          <span onClick={() => navigate("/login")}> Sign in</span>
        </AuthSubtitle>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={async (values: Values) => {
            forgotPassword(values);
          }}
          validationSchema={yup.object().shape(validationSchema.forgotPassword)}
        >
          {({ values, handleChange, errors, handleSubmit }) => (
            <>
              <TextInput
                required
                inputType="email"
                name="email"
                placeholder="Enter your email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
              />

              <ButtonComponent
                loading={loading}
                onClick={handleSubmit}
                text={"Reset Password "}
                style={{ marginTop: "10px" }}
              />
            </>
          )}
        </Formik>
      </AuthWrapper>
    </Auth>
  );
};

export default ForgotPassword;
