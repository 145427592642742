import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import {
  useAppDispatch,
  SupportedCurrencySelector,
  createWalletAction,
  getUsersWalletAction,
  getTransactionStats,
  getWalletTransactionsAction,
} from "@buzztechng/vermo-redux";
import { ModalComponent } from "../common/ModalComponent";
import { NotificationText } from "../common/StyleComponents";
import DropDown from "../common/DropDown";
import { ButtonComponent } from "../common/Button";
import { NotificationAlert } from "../../utils/NotificationAlert";

interface ModalInterface {
  onCancel: () => void;
  visible: boolean;
}
interface ValuesInterface {
  kind: string;
}
const CreateWalletModal: React.FC<ModalInterface> = ({
  onCancel,
  visible,
  children,
}) => {
  const dispatcher = useAppDispatch();
  const [loadingState, setLoading] = React.useState(false);
  const supportCurrency = SupportedCurrencySelector() as any;
  const currency = supportCurrency?.getAllSupportedCurrencies?.map(
    (value: any) => value?.kind
  );
  const createWallet = (values: ValuesInterface) => {
    const payload = {
      currencyId: values?.kind === "FIAT" ? 2 : 1,
    };
    setLoading(true);
    dispatcher(createWalletAction({ ...payload }))
      .unwrap()
      .then((originalPromiseResponse) => {
        setLoading(false);
        if (!originalPromiseResponse?.createWallet?.status) {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.createWallet?.msg
          );
        } else {
          dispatcher(getUsersWalletAction({}));
          dispatcher(getTransactionStats({}));
          dispatcher(getWalletTransactionsAction({}));
          NotificationAlert(
            "success",
            "Successful",
            originalPromiseResponse?.createWallet?.msg
          );
          onCancel();
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error occured", err.message);
      });
  };

  return (
    <>
      <ModalComponent visible={visible} onCancel={onCancel}>
        <div style={{ padding: "1vw", width: "100%" }}>
          <NotificationText>Create your Wallet</NotificationText>
          <br />
          <br />
          <Formik
            validationSchema={yup.object().shape({
              kind: yup.string().required("Please select a wallet Type"),
            })}
            onSubmit={(values: ValuesInterface) => {
              createWallet(values);
            }}
            initialValues={{
              kind: "",
            }}
          >
            {({ values, setFieldValue, errors, handleSubmit }) => (
              <>
                <DropDown
                  onChange={setFieldValue}
                  name="kind"
                  label="Wallet Type"
                  field={"kind"}
                  placeholder="Select your preferred wallet type"
                  values={currency}
                  error={errors.kind}
                />
                <ButtonComponent
                  loading={loadingState}
                  onClick={handleSubmit}
                  text={"Create Wallet"}
                  style={{ marginTop: "10px" }}
                />
              </>
            )}
          </Formik>
        </div>
        <br />
      </ModalComponent>
    </>
  );
};

export default CreateWalletModal;
