import { WalletSelector } from "@buzztechng/vermo-redux";
import React from "react";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop, mobile } from "../../theme/fontSize";
interface DropDownType {
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | any;
  values?: any;
  required?: boolean;
  placeholder?: string;
  label?: string;
  name?: string;
  field?: string;
  error?: string;
}
const CurencyDropDown = ({
  label,
  onChange,
  placeholder,
  required,
  values,
  name,
  field,
  error,
}: DropDownType) => {
  const [focus, setFocus] = React.useState(false);
  const { getNGNBanks } = WalletSelector() as any;
  return (
    <div style={{ marginBottom: 15 }}>
      {label && <Label>{label}</Label>}
      <Container
        focusProp={focus}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        <Dropdown
          name={name}
          placeholder={placeholder}
          required={required ? true : false}
          onChange={(e) =>
            // field ? onChange(field, e?.target?.value) : onChange(e.target.value
            // console.log("value", e.target.value)
            onChange(field, e?.target?.value)
          }
        >
          <Option disabled selected value="">
            {placeholder}
          </Option>
          {getNGNBanks?.getBanks?.length > 0
            ? getNGNBanks?.getBanks?.map((value: any, index: any) => (
                <Option
                  // defaultValue={values[1].label}
                  key={index}
                  value={value?.BankName + "," + value?.BankCode}
                >
                  {value?.BankName}
                </Option>
              ))
            : "Loading list..."}
        </Dropdown>
      </Container>
      {error && <Error>{error} !</Error>}
    </div>
  );
};
export const Error = styled.h5`
  margin: 0px 0 0 12px;
  font-weight: 300;
  font-size: ${mobile.small};
  color: red;
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export default CurencyDropDown;

interface ContainerProp {
  focusProp?: boolean;
}

export const Dropdown = styled.select`
  &&& {
    border: none;
    background: ${(props) => props.theme.foreground};
    width: 100%;
    padding: 5px 10px;
    outline: none;
    height: 100%;
    color: ${(props) => props.theme.textColor};
    font-size: 14px !important;
    ::placeholder {
      color: ${(props) => props.theme.mutedColorSecondary};
    }
    @media ${device.laptop} {
      /* background-color: re; */
      font-size: ${desktop.small} !important;
      /* height: 2.5vw; */
      cursor: pointer;
      &:focus,
      &:active {
        font-size: ${desktop.small};
        outline: none;
        border: none;
      }
    }
  }
`;
export const Container = styled.div<ContainerProp>`
  width: 100%;
  height: 40px;
  border: ${(props) =>
    `1px solid ${
      props.focusProp
        ? props.theme.primaryColor
        : props.theme.mutedColorSecondary
    }`};
  border-radius: 4px;
  padding: 0vw 0vw;
  display: flex;
  align-items: center;
  margin: 0;
  @media ${device.laptop} {
    height: 2.5vw;
  }
`;
export const Label = styled.h5`
  margin-bottom: 0;
  font-weight: 300;
  font-size: ${mobile.normal};
  color: ${(props) => props.theme.mutedColorTertiary};
  @media ${device.laptop} {
    font-size: ${desktop.small};
  }
`;
export const Option = styled.option`
  background-color: ${(props) => props.theme.background} !important;
  font-size: 14px !important;
  ::placeholder {
    color: ${(props) => props.theme.mutedColorSecondary};
  }
  @media ${device.laptop} {
    font-size: ${desktop.small} !important;
  }
`;
