import {
  confirmPayout,
  getTransactionStats,
  getWalletTransactionsAction,
  initPayOut,
  setUserPaymentDetails,
  useAppDispatch,
  WalletSelector,
} from "@buzztechng/vermo-redux";
import { Alert, Button, Col, Row } from "antd";
import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import { WalletScreen } from "../../../constants/types";
import { NotificationAlert } from "../../../utils/NotificationAlert";
import { ButtonComponent } from "../../common/Button";
import { NotificationText } from "../../common/StyleComponents";
import TextInput from "../../common/TextInput";
import { ModalText } from "../Style";
interface IConfirmScreen {
  close: (val: WalletScreen) => void;
  next: (val: WalletScreen) => void | any;
}
interface Values {
  otp: string;
}
const ConfirmScreen: React.FC<IConfirmScreen> = ({ close, next }) => {
  const { userPaymentDetails } = WalletSelector() as any;
  const [loading, setLoading] = React.useState(false);
  const [resetLoading, setResetLoading] = React.useState(false);

  const [time, setTimer] = React.useState(90);
  const tick = () => {
    setTimer(time - 1);
  };
  React.useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });
  const ResendToken = () => {
    setTimer(90);
    setResetLoading(true);
    dispatcher(
      initPayOut({
        amount: Math.floor(userPaymentDetails?.amount),
        userPayoutMethodId: userPaymentDetails?.userPayment?.id,
      })
    )
      .unwrap()
      .then((originalPromiseResponse) => {
        setResetLoading(false);
        if (originalPromiseResponse?.initPayOut?.status) {
          NotificationAlert(
            "success",
            "OTP SENT",
            "Check mail for Verification Token"
          );
        } else {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.initPayOut?.msg
          );
        }
        console.log("response", originalPromiseResponse);
      })
      .catch((err) => {
        setResetLoading(false);
        NotificationAlert("error", "Error occured", "Please try again.");
      });
    // next(WalletScreen.CONFIRM_PAYMENT);
  };
  const dispatcher = useAppDispatch();
  const onSubmit = async (values: Values) => {
    setLoading(true);
    dispatcher(
      confirmPayout({
        amount: Math.floor(userPaymentDetails?.amount),
        userPayoutMethodId: userPaymentDetails?.userPayment?.id,
        otp: values.otp,
      })
    )
      .unwrap()
      .then((originalPromiseResponse) => {
        setLoading(false);
        if (originalPromiseResponse?.confirmPayout?.status) {
          dispatcher(
            setUserPaymentDetails({
              ...userPaymentDetails,
              payOutResponse: { ...originalPromiseResponse?.confirmPayout },
            })
          );
          next(WalletScreen.SUCCESSFUL_PAYMENT);
          dispatcher(getWalletTransactionsAction({}));
          dispatcher(getTransactionStats({}));
        } else {
          NotificationAlert(
            "error",
            "Error occured",
            originalPromiseResponse?.confirmPayout?.msg
          );
        }
        console.log("response", originalPromiseResponse);
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error occured", "Please try again.");
      });
  };
  return (
    <div style={{ textAlign: "start" }}>
      <ModalText>Confirm Withdrawal</ModalText>

      <br />

      <NotificationText style={{ textAlign: "start" }}>
        A Verification code has been sent to your email. Kindly input in
        textfield below to confirm withdrawal.
      </NotificationText>
      <br />
      <Formik
        initialValues={{
          otp: "",
        }}
        onSubmit={(values: Values) => {
          onSubmit(values);
        }}
        validationSchema={yup.object().shape({
          otp: yup.string().required("This field is required"),
        })}
      >
        {({ values, handleChange, errors, handleSubmit }) => (
          <>
            <TextInput
              required
              inputType=""
              value={values.otp}
              name="otp"
              placeholder="Enter Verification code"
              onChange={handleChange}
              error={errors.otp}
              label="Verification Code"
            />
            <Alert
              showIcon
              message="Didn't receive token?"
              type="info"
              action={
                <Button
                  onClick={ResendToken}
                  loading={resetLoading}
                  disabled={time > 0}
                  size="small"
                  danger
                >
                  {time > 0 && time} Resend Token
                </Button>
              }
            />
            <Row style={{ width: "100%" }} justify="space-between">
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  colorprop={true}
                  onClick={() => close(WalletScreen.SELECT_PAYMENT_METHOD)}
                  text={"Previous"}
                  style={{ marginTop: "10px" }}
                />
              </Col>
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  onClick={handleSubmit}
                  loading={loading}
                  text={"Accept"}
                  style={{ marginTop: "10px" }}
                />
              </Col>
            </Row>
          </>
        )}
      </Formik>
      <br />
    </div>
  );
};

export default ConfirmScreen;
