import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { desktop } from "../../theme/fontSize";
import {
  Challenger,
  PageTitle,
  Selection,
  Team,
} from "../common/StyleComponents";
import {
  ChallengesSelector,
  GeneralDataSelector,
} from "@buzztechng/vermo-redux";
import { StyledTable } from "../common/TableComponent";
import moment from "moment";
import { formatNumberAmount } from "../../utils/helperfuntions";

const LockedChallenges = () => {
  const { userDetails } = GeneralDataSelector() as any;
  const columns = [
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, others: any) => (
        <div>
          <div style={{ display: "flex" }}>
            {moment(text).format("DD.MM.YYYY")}
            {others?.live && <Live>LIVE</Live>}
          </div>
          <div>{moment(text).format("hh:mm a")}</div>
        </div>
      ),
    },
    {
      title: "Fixtures",
      dataIndex: "team",
      key: "team",
      render: (props: any, others: any) => (
        <Team>
          <div className="content">
            {/* <img className="image" src={props?.homeLogo} alt={props.homeName} /> */}
            <p className="text">{others?.createdByTeam}</p>
          </div>
          <div className="content">
            <p className="text">VS</p>
          </div>
          <div className="content">
            {/* <img className="image" src={props?.awayLogo} alt={props.awayName} /> */}
            <p className="text">{others?.acceptedByTeam}</p>
          </div>
        </Team>
      ),
    },
    {
      title: "Game Type",
      dataIndex: "gameType",
      key: "gameType",
      // render: (text: string) => (
      //   <div>
      //     <Text fontSize={{ base: "xs", md: "small" }} fontWeight="bold">
      //       {moment(text).format("MMM D, YYYY")}
      //     </Text>
      //     <Text fontSize={{ base: "xs", md: "xs" }} fontWeight="normal">
      //       Submitted On
      //     </Text>
      //   </div>
      // ),
    },

    {
      title: "Selections",
      dataIndex: "selection",
      key: "selection",
      render: (text: string, other: any) => (
        <div>
          <Selection
            style={{
              border: `1px solid ${
                +other?.createdBy === +userDetails?.me?.id ? "green" : "red"
              }`,
            }}
          >
            {other?.createdByTeam}
          </Selection>
          <Selection
            style={{
              border: `1px solid ${
                +other?.acceptedBy === +userDetails?.me?.id ? "green" : "red"
              }`,
            }}
          >
            {other?.acceptedByTeam}
          </Selection>
        </div>
      ),
    },

    {
      title: "Winning",
      dataIndex: "amount",
      key: "amount",
      render: (text: string) => (
        <div>
          <Team>#{formatNumberAmount(+text * 2)}</Team>
        </div>
      ),
    },
    {
      title: "Challenger",
      dataIndex: "challenger",
      key: "challenger",
      render: (text: string, other: any) => (
        <div>
          <Challenger>{other?.createdUser?.name}</Challenger>
        </div>
      ),
    },
  ];

  const { myLockedChallenges, getMyLockedChallengeLoading } =
    ChallengesSelector() as any;
  const navigate = useNavigate();

  return (
    <div>
      <PageTitle>
        <h2>Selection description</h2>
      </PageTitle>
      <Desc>
        <div>
          <div className="mySelection"></div>
          <p>My selection</p>
          <div className="challengerSelection"></div>
          <p>Opponent's selection</p>
        </div>
      </Desc>
      <StyledTable
        scroll={{ x: 600 }}
        loading={getMyLockedChallengeLoading}
        columns={columns}
        dataSource={myLockedChallenges?.myLockedChallenges}
        onRow={(record: any) => {
          return {
            onClick: (e) => {
              console.log("records", record?.live);
              navigate("/details", {
                state: {
                  showChat: true,
                  showOdds: false,
                  lockedChallenges: true,
                  record,
                },
              });
            },
          };
        }}
      />
    </div>
  );
};

export default LockedChallenges;

export const Live = styled.div`
  font-size: ${desktop.xSmall};
  /* color: ${(props) => props.theme.textColor}; */
  padding: 3px 5px;
  font-weight: 500;
  background: ${(props) => props.theme.accentPrimary};
  margin-left: 10px;
  border-radius: 5px;
`;

export const Desc = styled.div`
  div:first-child {
    display: flex;
    align-items: center;
  }
  .mySelection {
    height: 10px;
    width: 10px;
    background: green;
    border-radius: 50%;
    margin-right: 5px;
    align-items: center;
  }
  .challengerSelection {
    height: 10px;
    width: 10px;
    background: red;
    border-radius: 50%;
    margin: 0 5px 0 15px;
  }
  p {
    margin: 0;
  }
`;
