import React from "react";
import { ButtonComponent } from "../../components/common/Button";
import { useNavigate } from "react-router-dom";
import {
  AuthSubtitle,
  AuthTitle,
  AuthWrapper,
} from "../../components/common/StyleComponents";
import TextInput from "../../components/common/TextInput";
import Auth from "../../components/layouts/Auth";
import { Formik } from "formik";
import * as yup from "yup";
import { validationSchema } from "../../utils/validationSchema";
import {
  loginAction,
  useAppDispatch,
  setLoginInfo,
  setRegistrationInfo,
  getUserAction,
} from "@buzztechng/vermo-redux";
import { NotificationAlert } from "../../utils/NotificationAlert";
import authentication from "../../hoc/authenticated";
interface Values {
  email: string;
  password: string;
}

const Login = () => {
  const dispatcher = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const login = (values: Values) => {
    setLoading(true);

    setRegistrationInfo(values);
    dispatcher(loginAction(values))
      .unwrap()
      .then((originalPromiseResponse) => {
        console.log("originalPromiseResponse", originalPromiseResponse);
        setLoading(false);
        if (originalPromiseResponse?.login?.status === true) {
          localStorage.setItem("token", originalPromiseResponse?.login?.token);
          setLoginInfo(originalPromiseResponse?.login?.user);
          // if (!localStorage.sub)
          // {
          //   dispatcher(getUserAction({}));
          //   navigate("/subscription");
          // } else {
          navigate("/dashboard");
          // }
          // console.log("i worked");
        } else {
          NotificationAlert(
            "error",
            "Error occurred",
            originalPromiseResponse?.login?.msg
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        NotificationAlert("error", "Error", err.message);
        // console.log("err", err.message);
      });
  };
  return (
    <Auth>
      <div style={{ zIndex: 1 }}>
        <AuthWrapper>
          <AuthTitle>Sign in to your Vermo account with email</AuthTitle>
          <AuthSubtitle>
            Don't have an Account?
            <span onClick={() => navigate("/register")}> Sign up</span>
          </AuthSubtitle>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={async (values: Values) => {
              login(values);
            }}
            validationSchema={yup.object().shape(validationSchema.login)}
          >
            {({ values, handleChange, errors, handleSubmit }) => (
              <>
                <TextInput
                  required
                  inputType="email"
                  name="email"
                  placeholder="Enter your email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email}
                />
                <TextInput
                  required
                  inputType="password"
                  name="password"
                  placeholder="Enter your Password"
                  label="Password"
                  value={values.password}
                  onChange={handleChange}
                  error={errors.password}
                />
                <ButtonComponent
                  loading={loading}
                  onClick={handleSubmit}
                  text={"Log in"}
                  style={{ marginTop: "10px" }}
                />
              </>
            )}
          </Formik>
        </AuthWrapper>
        <AuthSubtitle style={{ marginTop: "20px" }}>
          <span onClick={() => navigate("/forgot-password")}>
            Forgot your password?
          </span>
        </AuthSubtitle>
      </div>
    </Auth>
  );
};

export default authentication(Login);
