import { useLazyQuery } from "@apollo/client";
import {
  LeagueDetailsSelector,
  LiveGamesSelector,
  ScheduledGamesSelector,
} from "@buzztechng/vermo-redux";
import { FOOTBALL_FIXTURES_QUERY } from "@buzztechng/vermo-redux/lib/services/queries";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { updateMatchArray } from "../../utils/helperfuntions";
import Loading from "../common/Loading";
import { DivisionContainer } from "../common/StyleComponents";
import PairSport from "../others/SelectSports/PairSport";

const AllGamesHomePage = () => {
  const leaguesDetails = LeagueDetailsSelector() as any;

  const [fixturePage, setFixturePage] = useState(0);
  const [fixtureDate, setFixtureDate] = useState(
    moment(new Date().toISOString())
  );
  const scheduledGameList = ScheduledGamesSelector() as any;

  const [fixtureLeague, setFixtureLeague] = useState(39);
  const [fetchFixtures, { data, loading, error, fetchMore, refetch }] =
    useLazyQuery(FOOTBALL_FIXTURES_QUERY, {
      variables: {
        currentPage: fixturePage,
        dateFilter: fixtureDate,
        leagueFilter: fixtureLeague,
      },
    });
  //@ts-ignore
  const formattedLeague = () => {
    let formattedData = {} as any;

    //@ts-ignore
    if (leaguesDetails?.getLeagues) {
      for (const data of leaguesDetails?.getLeagues) {
        formattedData[data?.apiFootballLeagueId] = data;
      }
      return formattedData;
    }
    return [];
  };
  useEffect(() => {
    fetchFixtures({
      variables: {
        dateFilter: fixtureDate,
        leagueFilter: fixtureLeague,
        currentPage: fixturePage,
      },
    });
  }, []);

  const formattedLeagues = formattedLeague();

  const AllGames = React.useMemo(() => {
    return updateMatchArray(
      data?.footballFixtures?.data || [],
      formattedLeagues,
      true
    )?.map((football, key) => {
      return (
        <>
          <DivisionContainer key={key}>
            <div className="wrapper">
              <img src={football?.countryFlag} alt="" />
              <p className="country">{football?.country}</p>
            </div>
            <div className="wrapper">
              <img src={football?.divisionFlag} alt={football?.division} />
              <p className="division">{football?.division}</p>
            </div>
            <div></div>
          </DivisionContainer>
          <br />
          <PairSport matchDets={football?.match} data={football} />
        </>
      );
    });
  }, [data]);

  return (
    <>
      {loading ? (
        <>
          <Loading skeleton={true} />
        </>
      ) : (
        AllGames
      )}
    </>
  );
};

export default AllGamesHomePage;
