import { Formik } from "formik";
import React from "react";
import { DetailsText, ModalText } from "../Style";
import * as yup from "yup";
import { ButtonComponent } from "../../common/Button";
import TextInput from "../../common/TextInput";
import { Col, Row } from "antd";
import { WalletScreen } from "../../../constants/types";
import {
  useAppDispatch,
  setUserPaymentDetails,
  WalletSelector,
  GeneralDataSelector,
} from "@buzztechng/vermo-redux";
import { formatNumberAmount } from "../../../utils/helperfuntions";
interface IAmount {
  close: () => void;
  next: (val: WalletScreen) => void;
  loading: boolean;
}
const Amount: React.FC<IAmount> = ({ close, next, loading }) => {
  interface Values {
    amount: any;
  }
  const { userPaymentDetails, getRates } = WalletSelector() as any;
  const dispatcher = useAppDispatch();
  const generalState = GeneralDataSelector() as any;
  const userDetails = generalState?.userDetails?.me;
  const onSubmit = async (values: Values) => {
    next(WalletScreen.CREATE_PAYMENT_METHOD);
    await dispatcher(
      setUserPaymentDetails({
        ...userPaymentDetails,
        amount: values.amount,
      })
    );
  };

  return (
    <div style={{ textAlign: "start" }}>
      <ModalText>Withdraw Funds</ModalText>
      <Formik
        initialValues={{
          amount: userPaymentDetails?.amount,
        }}
        onSubmit={(values: Values) => {
          onSubmit(values);
        }}
        validationSchema={yup.object().shape({
          amount: yup
            .number()
            .required("Please input a valid amount")
            .min(3, "Your minimum withrawable amount is $3 (3 usd)"),
        })}
      >
        {({ values, handleChange, setFieldValue, errors, handleSubmit }) => (
          <>
            <TextInput
              required
              inputType="number"
              value={values.amount}
              name="amount"
              placeholder="Enter your withdraw amount in usd($)"
              onChange={handleChange}
              error={errors.amount}
              label="Amount (usd $)"
            />
            {values?.amount && (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <DetailsText>Amount in local currrency :</DetailsText>
                <DetailsText>
                  #
                  {formatNumberAmount(
                    +values.amount * +getRates[userDetails?.defaultCurrency]
                  )}
                </DetailsText>
              </div>
            )}
            <Row style={{ width: "100%" }} justify="space-between">
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  colorprop={true}
                  onClick={close}
                  text={"Close"}
                  style={{ marginTop: "10px" }}
                />
              </Col>
              <Col sm={{ span: 11 }} md={{ span: 11 }}>
                <ButtonComponent
                  onClick={handleSubmit}
                  loading={loading}
                  text={"Continue"}
                  style={{ marginTop: "10px" }}
                />
              </Col>
            </Row>
          </>
        )}
      </Formik>
      <br />
    </div>
  );
};

export default Amount;
