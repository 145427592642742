import { BsPlusLg } from "react-icons/bs";
import styled from "styled-components";
import { device } from "../../theme/breakPoints";
import { desktop } from "../../theme/fontSize";
interface ChatProps {
  isMax?: boolean;
}
export const ConfirmBet = styled.p`
  text-align: center;
  color: ${(props) => props.theme.textColor};
  font-weight: 400;
  margin: 0;
  font-size: ${desktop.normal};
`;
export const ConfirmBetContent = styled.div`
  .details {
    font-style: italic;
    font-weight: 300;
    color: ${(props) => props.theme.textColor};
    margin: 0;
    margin-bottom: 10px;
    font-size: ${desktop.xSmall};
  }
  .detailsInfo {
    font-weight: 300;
    color: ${(props) => props.theme.textColor};
    margin: 0;
    margin-bottom: 10px;
    span {
      font-weight: 500;
    }
  }
`;
export const Events = styled.div`
  .eTitle {
    font-weight: 400;
    font-size: ${desktop?.normal};
    color: ${(props) => props.theme.textColor};
  }
  .divider {
    padding: 10px 15px;
    border-top: ${(props) => `1px solid ${props.theme.mutedColorSecondary}`};
  }
`;
export const ChatContainer = styled.div<ChatProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: ${(props) => (props.isMax ? "100vh" : "0")};
  width: ${(props) => (props.isMax ? "100vw" : "0")};
  @media ${device.laptop} {
    height: ${(props) => (props.isMax ? "400px" : "300px")};
    width: 300px;
  }
`;
export const AddBet = styled(BsPlusLg)`
  font-size: ${desktop.medium};
  color: ${(props) => props.theme.textColor};
  padding: 0;
  margin: 0;
`;
export const AddBetContainer = styled.div`
  span {
    font-size: 13px !important;
  }
`;
